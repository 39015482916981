import React, {useEffect, useRef, useState} from "react";
import cookie from "react-cookies";
import axios from "axios";
import {Logout, NotificationComponent} from "../../functions/functions";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import {StartPhishingBody} from "./startPhishingBody";
import classNames from "classnames";

export const StartPhishing = (props) => {
    const [arrayScenarioIdAndSubjectForLettersSending, setArrayScenarioIdAndSubjectForLettersSending] = useState(null);
    const [isStaffListAdded, setIsStaffListAdded] = useState(false);
    const [acceptedRules, setAcceptedRules] = useState(false);
    const [arrayScenarioIdAndSubject, setArrayScenarioIdAndSubject] = useState(null)
    const [isActive, setIsActive] = useState(false);
    const [lang, setLang] = useState('ru')
    const [scenarioId, setScenarioId] = useState(null);
    const [popupTxt, setPopupTxt] = useState(null);
    const [amountsOfPeople, setAmountsOfPeople] = useState(null);
    const [durationOfPhishing, setDurationOfPhishing] = useState(90);
    const [classList, setClassList] = useState({
              'popup_btn-contaigner_send-letter-to-myself': {
                'hide': true,
              },
              'scenario-chosed_btn': {
                  'not-active-btn': false,
                  'disabled': false,
              },
                'popup-start-phishing': {
                  'hide': true,
                },
                'popup-overlay': {
                  'hide': true,
                },
                'popup_btn-contaigner_start-phishing':{
                  'hide': true,
                }

            })
    const dayOfPhishingCampaign = useRef()

    useEffect(() => {
        funcIsAcceptRuls();
        funcIsStaffListAdded();
        funcSelectScenariosForLettersSending();
        funcIsActive();
        funcSelectedScenarios();
    }, []);


    const changeClass = (elementClass, key, value) => {
        setClassList(prevState => ({
        ...prevState,
        [elementClass]: {
          ...prevState[elementClass],
          [key]: value
        }
      }));
    };

    const funcSelectedScenarios = () => {
        axios
          .get(`${props.host}selectscenaries`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                let arrayScenarioIdAndSubject = response.data.arrayScenarioIdAndSubject;
                setArrayScenarioIdAndSubject(arrayScenarioIdAndSubject)
              } else if (response.data.status === "failed") {
                alert("Session Expired");
                Logout();
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    const funcIsAcceptRuls = () => {
        axios
          .get(`${props.host}isacceptedruls`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                  setAcceptedRules("accepted")
              } else {
                  setAcceptedRules("no accepted")
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    const funcIsStaffListAdded = () => {
        axios
          .get(`${props.host}isstafflistadded`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                if (response.data.result > 0) {
                    setIsStaffListAdded(true);
                    setAmountsOfPeople(response.data.result)
                } else {
                  console.log("list is not added");
                  changeClass('scenario-chosed_btn', 'disabled', true);
                  changeClass('scenario-chosed_btn', 'not-active-btn', true);
                }
              } else if (response.data.status === "failed") {
                 console.log("response failed in funcIsStaffListAdded (startPhishing component)");
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    const funcIsActive = async () => {
        try{
            const response = await axios.get(
                `${props.host}isscenarioactive`,
                    {headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            }})

            if(response.data.status === 'success'){
                let active = false;
                response.data.result.forEach(el => {
                    if(el.active){
                        active = true;
                    }
                })
                setIsActive(active);
            }
        } catch(error) {
            console.log('Error:', error);
        }
    }

    const funcSelectScenariosForLettersSending = () => {
        axios
          .get(`${props.host}selectscenariesforleterssending`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                let arrayScenarioIdAndSubjectForLetersSending = response.data.arrayScenarioIdAndSubject;
                setArrayScenarioIdAndSubjectForLettersSending(arrayScenarioIdAndSubjectForLetersSending)
              } else if (response.data.status === "failed") {
                alert("Session Expired");
                Logout();
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    const activateAllScenarios = () =>  {
        closePopup();
        axios
          .get(`${props.host}activatescenariosinscenariotable`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                  setIsActive(true)
                  toast.success(response.data.message);
              } else if (response.data.status === "failed") {
                  toast.warning(response.data.message)
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });


        let dayInterval = Math.ceil(600000 / amountsOfPeople);
        let scenarios = arrayScenarioIdAndSubjectForLettersSending;

        let data = JSON.stringify({
          scenarios: scenarios,
          dayInterval: dayInterval,
          lang: lang,
          startPhishingCampaign: true,
          durationOfPhishing: durationOfPhishing,
        });

        axios
          .post(`${props.host}sendletter`, data, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                //  console.log('success');
              } else if (response.data.status === "failed") {
                //  console.log("failed")
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    const askAboutSendLetterTOMYself = (e) => {
        if (!isActive) {
            setPopupTxt("Send yourself a test email?")
            changeClass('popup_btn-contaigner_send-letter-to-myself', 'hide', false);

            let scenarioid = e.currentTarget.id;
            setScenarioId(scenarioid)
            changeClass('popup-start-phishing', 'hide', false);
            changeClass('popup-overlay', 'hide', false);
        }
    }

    const askAboutStartPhishing = () => {
        if (!isActive) {
            setPopupTxt("Are you certian you want to launch the phishing campaign? Once you start you will NOT be able to edit any scenarios.")
            changeClass('popup_btn-contaigner_start-phishing', 'hide', false);
            changeClass('popup-overlay', 'hide', false);
            changeClass('popup-start-phishing', 'hide', false);
            setDurationOfPhishing(dayOfPhishingCampaign.current.value)
        }
    }

    const closePopup = () => {
        changeClass('popup-start-phishing', 'hide', true);
        changeClass('popup-overlay', 'hide', true);
        changeClass('popup_btn-contaigner', 'hide', true);
        changeClass('popup_btn-contaigner_send-letter-to-myself', 'hide', true);

        setScenarioId(null);
        setPopupTxt(null)
    }

    const overlayOnClick = () => {
        changeClass('popup-overlay', 'hide', true);
        closePopup();
    }

    const sendLetterToMyself = () => {
        const handleResult = (toastAction, data) => {
            changeClass('popup_btn-contaigner_send-letter-to-myself', 'hide', true);
            toast[toastAction](data)
            setTimeout(() => {
                closePopup();
            }, 3000);
        }

        let data = JSON.stringify({
          scenarioid: scenarioId,
          lang: lang,
          startPhishingCampaign: false,
        });

        axios
          .post(`${props.host}sendletter`, data, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                  handleResult('success', response.data.message)
              } else {
                  handleResult('warning', response.data.message)
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }


    return (
        <>
            <NotificationComponent position="top-right"/>
            <div className="StartPhishing ">
                <div className={classNames("popup-overlay", {
                    hide: classList['popup-overlay'].hide
                })} onClick={overlayOnClick}></div>
                {acceptedRules === "accepted" ? (
                    <div>
                        {isStaffListAdded === false ? (
                            <div className="informationBlock">
                                <div className="startphishing-warning-about-empty-stafflist errorMassage">
                                    <p>You still haven't added people to your Staff to be targeted during the phishing
                                        campaign. </p>
                                    <p>
                                        Go to the section <NavLink to="/cabinet/list">STAFF</NavLink>
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {isActive === true ? (
                                    <div className="informationBlock">
                                        <div className="startphishing-warning-about-empty-stafflist errorMassage">
                                            <p>
                                                The scenarios are locked cannot be edited until campaign is completed or
                                                restarted.
                                                <br/>
                                                View <NavLink to="/cabinet/reports">REPORTS</NavLink> if you want to
                                                see progress on active campaign.
                                            </p>
                                        </div>
                                        <StartPhishingBody
                                            popupTxt={popupTxt}
                                            sendLetterToMyself={sendLetterToMyself}
                                            closePopup={closePopup}
                                            activateAllScenarios={activateAllScenarios}
                                            arrayScenarioIdAndSubject={props.arrayScenarioIdAndSubject}
                                            askAboutSendLetterTOMYself={askAboutSendLetterTOMYself}
                                            classList={classList}
                                        />
                                    </div>
                                ) : (
                                    <StartPhishingBody
                                        popupTxt={popupTxt}
                                        sendLetterToMyself={sendLetterToMyself}
                                        closePopup={closePopup}
                                        activateAllScenarios={activateAllScenarios}
                                        arrayScenarioIdAndSubject={props.arrayScenarioIdAndSubject}
                                        askAboutSendLetterTOMYself={askAboutSendLetterTOMYself}
                                        classList={classList}
                                    />
                                )}

                                {isActive ? null : (
                                    <div className="start-scenario_activate-all_btn_contaigner">
                                        <p>
                                            <label>Choose the duration of the Phishing campaign. This is the period of
                                                time during which employees will receive phishing emails. The default
                                                period is 90 days.</label>
                                            <select className="days-of-phishing-campaign" ref={dayOfPhishingCampaign}>
                                                <option value="1">1 day</option>
                                                <option value="30">30 days</option>
                                                <option value="60">60 days</option>
                                                <option value="90" selected>
                                                    90 days
                                                </option>
                                                <option value="120">120 days</option>
                                                <option value="150">150 days</option>
                                                <option value="180">180 days</option>
                                            </select>
                                        </p>
                                        <button
                                            className={classNames(" scenario-chosed_btn scenario-chosed_btn-start btn_start-phishing", {
                                                'not-active-btn': classList['scenario-chosed_btn']["not-active-btn"]
                                                // 'not-active-btn': false,
                                            })}
                                            // disabled={false}
                                            disabled={classList['scenario-chosed_btn'].disabled}
                                            onClick={askAboutStartPhishing}>
                                            Start Phishing
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : acceptedRules === "no accepted" ? (
                    <div className="doNotAcceptetRuls">
                        <div className="doNotAcceptetRuls  ">
                            <p>Launching scripts is not available for users who have not filled in personal information
                                and have not agreed to the terms of use of the site.</p>
                            <p>
                                Please go to the section <NavLink to="/cabinet/profile">PROFILE</NavLink> and fill
                                in the required information.
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="Profile profile-container">
                        <div className="loader_contaigner">
                            <div className="loader"></div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}