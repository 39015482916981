// import "animate.css";
import cookie from "react-cookies";
import {ToastContainer} from "react-toastify";

export const Logout = () => {
  cookie.remove("xtoken", { path: "/" });
  window.location = "/";
}

export const NotificationComponent = (props) => { // only one prop is position (top-right top-center etc)
  return (
      <>
        <ToastContainer
          position={props.position}
          autoClose="2000"
          hideProgressBar="false"
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
  )
}
