import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

export const StartPhishingBody = (props) => {
    return (
        <>
            <div className="start-phishing_attention-massage_contaigners information-block">
                <div className="information-block_top">
                    <h5>Attention!</h5>
                </div>
                <div className="information-block_bottom">
                    Once the phishing begins, the process cannot be canceled. Employees from the <NavLink
                    to="/cabinet/list">STAFF LIST</NavLink> which you upload earlier will be tested against the
                    scenarios provided. The results will be logged in the <NavLink
                    to="/cabinet/reports">REPORTS</NavLink>. After the scenarios are launched <NavLink
                    to="/cabinet/list">STAFF</NavLink>{" "}
                    and <NavLink to="/cabinet/scenaries">SCENARIOS</NavLink> <span
                    className="bold-font"> Will NOT </span> be available for editing.
                    <br/>
                    <br/>
                    <p>Before you start your Phishing campaign, feel free to test your scenario by sending it to
                        yourself</p>
                    <div className="start-scenarios-active_contaigner">
                        <div className={classNames("popup-start-phishing popup__ask-obout-send-letter-to-my-self", {
                            hide: props.classList['popup-start-phishing'].hide
                        })}>
                            <p className="popup-txt">{props.popupTxt}</p>
                            <div className={classNames("popup_btn-contaigner popup_btn-contaigner_send-letter-to-myself", {
                                'hide': props.classList['popup_btn-contaigner_send-letter-to-myself'].hide
                            })}>
                                <button className="popup-yes-btn" onClick={props.sendLetterToMyself}>
                                    Send
                                </button>
                                <button className="popup-no-btn" onClick={props.closePopup}>
                                    Cancel
                                </button>
                            </div>
                            <div className={classNames("popup_btn-contaigner popup_btn-contaigner_start-phishing", {
                                hide: props.classList['popup_btn-contaigner_start-phishing'].hide
                            })}>
                                <button className="popup-yes-btn" onClick={props.activateAllScenarios}>
                                    Start
                                </button>
                                <button className="popup-no-btn" onClick={props.closePopup}>
                                    Cancel
                                </button>
                            </div>
                        </div>

                        <table className="scenaries__list__table-start-phishing">
                            <tbody>
                            {props.arrayScenarioIdAndSubject.map((scenario, index) => (
                                <tr
                                    className={"scenario-chosed scenario-chosed-" + scenario.id}
                                    id={scenario.id}
                                    key={index}
                                    tittle="Send a test letter to your own account."
                                    onClick={props.askAboutSendLetterTOMYself}
                                >
                                    <td className="scenario-chosed_name">
                                        {" "}
                                        <div className="scenario-chosed_name-scenario-name">{scenario.subject}</div>
                                        {" "}
                                        <div className="scenario-chosed_name-scenario-icon">
                                            <i className="fas fa-paper-plane"></i>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}