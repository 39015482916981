import './WhatIsPhishing.css';
import React from "react";
import "../mainpage/css/mainpage.css";
import "../../css/style.css";
import manWithCash from "../../assets/images/mainpage/humanrisks/manWithCash.png";
import boyWithFish from "../../assets/images/mainpage/firstscreen/boyWithFish.png";
import { FormattedMessage } from 'react-intl';


export const WhatIsPhishing = (props) => {

    return (
        <>
            <div className="What-is-phishing">
                <div className="langChanger">
                    <a className="language-changer" onClick={props.changeLanguage} href="#">
                    En/Ru
                    </a>
                </div>
        
                <div className="Firstscreen layout container__fixed">
                    <div className="firstscreen__left">
                        <div className="firstscreen__container ">
                            <div className="firstscreen__header">
                                <h1>
                                <FormattedMessage id="phished_you"/>
                                <br />
                                <FormattedMessage id="phished_were"/>
                                <br />
                                <FormattedMessage id="phished_phished"/>
                                </h1>
                            </div>
                        </div>
                    </div>
        
                    <div className="firstscreen__right">
                    <img src={boyWithFish} className="boyWithFish" alt="about" />
                    </div>
                </div>
        
                <div className="be-vigilant_contaigner">
                    <div className="be-vigilant container__fixed ">
                        <div>
                        <p>
                            <FormattedMessage id="phished_you_here"/>
                            <br />
                            <FormattedMessage id="phished_good_news"/>
                        </p>
                        </div>
                    </div>
                </div>
        
                <div className="what-is-phishing_contaigner">
                    <div className="what-is-phishing container__fixed">
                            <h2><FormattedMessage id="mp_phishing_whatis"/></h2>
                            <p>
                            <FormattedMessage id="mp_phishing_text"/>
                            </p>
                            <p>
                            <FormattedMessage id="mp_humanrisks_notonly"/>
                            <br />
                            <FormattedMessage id="mp_humanrisks_often"/>
                            <br />
                            <FormattedMessage id="mp_humanrisks_fora"/>
                            </p>
                    </div>
                </div>
        
                <div className="humanrisks container__fixed ">
                    <div className="humanrisks__block layout">
                        <img src={manWithCash} className="manWithCash" alt="manWithCash" />
                        <div className="humanrisks__block__txt">
                        <h4>
                            {" "}
                            <FormattedMessage id="phished_howto"/> <br /> <FormattedMessage id="phished_ask_yourself"/>
                        </h4>
                        <p>
                            <ul>
                            <li><FormattedMessage id="phished_howdoes"/></li>
                            <li><FormattedMessage id="phished_doesthe"/></li>
                            <li><FormattedMessage id="phished_is_sender"/></li>
                            <li><FormattedMessage id="phished_does_email"/></li>
                            <li><FormattedMessage id="phished_are_being"/></li>
                            </ul>
                        </p>
                        </div>
                    </div>
                </div>
        
                <div className="damages container__fixed ">
                    <h2><FormattedMessage id="mp_damages_why"/></h2>
                    <div className="damages__blocks_container layout ">
                        <div className="damages__block">
                        <div className="damages__block_top">
                            <h2>{`<90%`}</h2>
                        </div>
                        <div className="damages__block_bottom damages__block_bottom_first">
                            <p className="damages__block__text"><FormattedMessage id="mp_damages_ofsuccessful"/></p>
                        </div>
                        </div>
        
                        <div className="damages__block">
                        <div className="damages__block_top">
                            <h2> 15% </h2>
                        </div>
                        <div className="damages__block_bottom">
                            <p className="damages__block__text"><FormattedMessage id="mp_damages_ofphished"/></p>
                        </div>
                        </div>
        
                        <div className="damages__block">
                        <div className="damages__block_top">
                            <h2> 1 of 130 </h2>
                        </div>
                        <div className="damages__block_bottom">
                            <p className="damages__block__text"><FormattedMessage id="mp_damages_emails"/></p>
                        </div>
                        </div>
                    </div>
                </div>
        
                <div className="footer ">
                    <div className="what-is-phish_footer">
                        <p><FormattedMessage id="phished_be_careful"/></p>
                    </div>
                </div>
            </div>
        </>
    )
}
