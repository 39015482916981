import React, { useEffect, useRef, useState } from 'react'
import './css/cabinet.css'
import cookie from 'react-cookies'
import axios from 'axios'
import { Logout } from '../functions/functions'
import { Render } from './Render'
import translationsConfig from '../../translations/translationsConfig'

export default function Cabinet(props) {
  const [arrayScenarioIdAndSubject, setArrayScenarioIdAndSubject] = useState([])
  const [isScenarioActive, setIsScenarioActive] = useState(false)
  const [isAcceptRules, setIsAcceptRules] = useState(null)
  const [pagesCount, setPagesCount] = useState(0)
  const [statisticsInfo, setStatisticsInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [statisticsPhishing, setStatisicPhishing] = useState(null)
  const [scenariosSubjects, setScenariosSubjects] = useState(null)


  const [menuOpened, setMenuOpened] = useState(false)

  const showMenu = () => setMenuOpened(true)
  const hideMenu = () => setMenuOpened(false)


  const [updated, setUpdated] = useState(false)

  const menuCabinetItems = useRef([]);

  useEffect(() => {
  const handleChangeWidth = () => {
    if (window.innerWidth >= 978) {
      setMenuOpened(false);
    }
  };

  window.addEventListener('resize', handleChangeWidth);

  return () => {
    window.removeEventListener('resize', handleChangeWidth);
  };
}, []);

  useEffect(() => {
    const fetchData = () => {
      const promises = [
        selectStatisticsInfo(),
        selectClientInfo(),
        selectPhishingInfo(),
        selectedScenarios(),
        selectScenariosSubject(),
        selectIsScenarioActive(),
      ]

      Promise.all(promises)
        .then(() => {
          checkDataLoaded()
        })
        .catch((error) => {
          console.error(error)
        })
    }

    fetchData()

    const savedLanguage = localStorage.getItem('CURRENTLANGUAGE')
    if (savedLanguage) {
      translationsConfig.CURRENTLANGUAGE = savedLanguage
    }
  }, [updated])

  useEffect(() => {
    // componentDidUpdate
    checkDataLoaded()
  }, [
    clientInfo,
    statisticsInfo,
    statisticsPhishing,
    scenariosSubjects,
    arrayScenarioIdAndSubject,
  ])

  const checkDataLoaded = () => {
    if (
      statisticsInfo &&
      clientInfo &&
      statisticsPhishing &&
      arrayScenarioIdAndSubject
    ) {
      setUpdated(true)
    }
  }

  const selectStatisticsInfo = () => {
    axios
      .get(`${props.host}selectstatisticinfo`, {
        headers: {
          'Content-Type': 'application/json',
          xtoken: cookie.load('xtoken'),
        },
      })
      .then(function (response) {
        if (response.data.status === 'success') {
          let statisticinfo = response.data.result
          setStatisticsInfo(statisticinfo)
        } else if (response.data.status === 'failed') {
          setStatisticsInfo({
            countConfirmed: 0,
            countActive: 0,
          })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const selectClientInfo = () => {
    axios
      .get(`${props.host}selectsclientinfo`, {
        headers: {
          'Content-Type': 'application/json',
          xtoken: cookie.load('xtoken'),
        },
      })
      .then(function (response) {
        if (response.data.status === 'success') {
          let clientInfo = response.data.result[0]
          setClientInfo(clientInfo)
        } else if (response.data.status === 'failed') {
          alert('Session Expired')
          Logout()
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const selectPhishingInfo = () => {
    axios
      .get(`${props.host}selectphishinginfo`, {
        headers: {
          'Content-Type': 'application/json',
          xtoken: cookie.load('xtoken'),
        },
      })
      .then(function (response) {
        if (response.data.status === 'success') {
          let statisicPhishing = response.data.result
          // console.log('HERE', response.data.result)
          setStatisicPhishing(statisicPhishing)
        } else if (response.data.status === 'failed') {
          setStatisicPhishing({
            amountssendedLetters: 0,
            amountsOfPeople: 0,
            date1: 0,
            date2: 0,
            date3: 0,
          })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const selectedScenarios = () => {
    axios
      .get(`${props.host}selectscenaries`, {
        headers: {
          'Content-Type': 'application/json',
          xtoken: cookie.load('xtoken'),
        },
      })
      .then(function (response) {
        if (response.data.status === 'success') {
          let arrayScenarioIdAndSubject = response.data.result;
          setArrayScenarioIdAndSubject(arrayScenarioIdAndSubject);
        } else if (response.data.status === 'failed') {
          alert('Session Expired')
          Logout()
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const selectScenariosSubject = () => {
    axios
      .get(`${props.host}selectscenariosforreportpicker`, {
        headers: {
          'Content-Type': 'application/json',
          xtoken: cookie.load('xtoken'),
        },
      })
      .then(function (response) {
        if (response.data.status === 'success') {
          const scenariosSubjectsx = response.data.result[0];
          setScenariosSubjects(scenariosSubjectsx);
        } else if (response.data.status === 'false') {
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const selectIsScenarioActive = async () => {
         axios
          .get(`${props.host}isscenarioactive`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
                if(response.data.status === 'success'){
                    const result = response.data.result
                    let active = false;

                    result.forEach((element) => {
                        if(element.active) active = true
                    })
                    setIsScenarioActive(active);
                }
            }
          )
          .catch(function (error) {
            console.log(error);
            setIsScenarioActive(false);
          });
      }

  const selectIsAcceptRuls = () => {
    axios
      .get(`${props.host}isacceptedruls`, {
        headers: {
          'Content-Type': 'application/json',
          xtoken: cookie.load('xtoken'),
        },
      })
      .then(function (response) {
        if (response.data.status === 'success') {
          setIsAcceptRules(true)
        } else {
          console.log('failed')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const showProfile = () => {
    selectStatisticsInfo()
    selectClientInfo()
    selectPhishingInfo()
    selectScenariosSubject()
    hideMenu()
  }

  const showScenaries = () => {
    selectIsScenarioActive()
    selectScenariosSubject();
    hideMenu()
  }

  const showStartPhishing = () => {
    selectIsAcceptRuls()
    hideMenu()
  }

  const topFunction = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  return (
    <>
      <Render
        showMenu={showMenu}
        hideMenu={hideMenu}
        menuOpened={menuOpened} // here
        showProfile={showProfile}
        showScenaries={showScenaries}
        showStartPhishing={showStartPhishing}
        topFunction={topFunction}
        isScenarioActive={isScenarioActive}
        pagesCount={pagesCount}
        host={props.host}
        statisticsInfo={statisticsInfo}
        statisticsPhishing={statisticsPhishing}
        scenariosSubjects={scenariosSubjects}
        clientInfo={clientInfo}
        arrayScenarioIdAndSubject={arrayScenarioIdAndSubject}
        selectedScenarios={selectedScenarios}

        menuCabinetItems={menuCabinetItems}
        updated={updated}
      />
    </>
  )
}
