import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';

export const CSVToHTMLTable = (props) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (props.csvData) {
      Papa.parse(props.csvData, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (result) => {
          setTableData(result.data);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        },
      });
    }
  }, [props.csvData]);

  const generateTable = () => {
    return (
      <table className={props.tableClassName}>
        <thead>
          <tr>
            {tableData.length > 0 &&
              Object.keys(tableData[0]).map((header, index) => (
                <th key={index}>{header}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((value, colIndex) => (
                <td key={colIndex} className={props.tdClassName}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {tableData.length > 0 ? (
        generateTable()
      ) : (
        <p>No data available or invalid CSV format.</p>
      )}
    </div>
  );
};