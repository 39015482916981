import React from "react";

export const en = {
    phished_you: "You",
    phished_were: "were",
    phished_phished: "phished!",
    phished_you_here: "You are here because you followed a link in an email or downloaded an attached file. In fact, the address is fake, and the sender is actually a hacker trying to get your data.",
    phished_good_news: "Good news? Such attacks can serve as a wake-up call. No data was transferred and no malware was installed. Be vigilant about all emails that end up in your inbox. Even clicking on a link in an e-mail can install viruses. Report anything suspicious to the IT (security) department.",
    phished_howto: "How to recognize phishing?",
    phished_ask_yourself: "Ask yourself a question",
    phished_howdoes: "How does the sender know your address and why are you seeing the e-mail?",
    phished_doesthe: "Does the content make sense and does the language fit the sender?",
    phished_is_sender: "Is the sender sending the e-mail under the correct name? ",
    phished_does_email: "Does the e-mail contain any suspicious links or attachments?",
    phished_are_being: "Are you being pressured to do something or are they insistently offering you something? ",
    phished_be_careful: "Be careful!",

    mp_main_page: 'Main page',
    mp_about_phishing: 'About phishing',
    mp_about_website: "About web-site",
    mp_login: "Login",

    mp_firstscreen_harden: "harden your company",
    mp_firstscreen_against: "against phishing attacks",

    mp_phishing_whatis: "What is phishing?",
    mp_phishing_text: "Phishing is a type of Internet fraud, the purpose of which is to gain access to confidential user data - logins and passwords. This is achieved through mass mailing of emails on behalf of popular brands, private messages within various services, for example, on behalf of banks or within social networks. The email often contains a direct link to a site that looks indistinguishable from the real one, or to a site with a redirect. After the user lands on a fake page, the scammers try to entice the user to enter their username and password on the fake page, which they use to access a specific site, which allows the fraudsters to gain access to accounts and bank accounts.",

    mp_humanrisks_notonly: "Not only executives and top managers of the company become victims of phishing, but also other groups of employees.\n",
    mp_humanrisks_often: "Often, сybercriminals attack the weakest link in an organization's security chain - an ordinary employee.\n",
    mp_humanrisks_fora: "For a successful attack, it is enough to pretend to be a person whom the user trusts in e-mails (colleague, security or technical support employee, bank employee, government employee)\n",

    mp_damages_why: "Why phishing is dangerous?",
    mp_damages_ofsuccessful: "of successful cyberattacks used phishing as an initial penetration vector",
    mp_damages_ofphished: "of phished users will be successfully phished the same year again",
    mp_damages_emails: "emails received by a regular user usually contain malware",
    mp_damages_of: "of",

    mp_about_howcan: "How can our website help you to protect your company from phishing attacks?",
    mp_about_text: "Our site provides an opportunity to test your employees for resistance to phishing attacks. Registered users have access to various phishing scenarios in their personal account. All you need is to select the appropriate scenario and upload the list of employees of your organization who are supposed to be phished in the simulation. You will receive detailed analytics after the phishing simulation has completed. This information gives you opportunity to identify the weaknesses of the organization and to train your employees for the correct response to cyber attacks.",

    mp_steps_how: "How it works?",
    mp_steps_registration: "Registration",
    mp_steps_load: "load the employee\nfor simulation",
    mp_steps_scenario: "Scenario selection",
    mp_steps_launch : "Launch phishing simulation",
    mp_steps_getting: "Getting statistics",

    cabinet_link_profile: 'Profile',
    cabinet_link_staff: 'Staff list',
    cabinet_link_scenarios: 'Scenarios',
    cabinet_link_launch: 'Phishing launch',
    cabinet_link_reports: 'Reports',
    cabinet_link_passpayload: 'Password Change Payload',
    cabinet_link_loginpayload: 'Login Page Payload',

    login_email: 'Email',
    login_password: 'Password',
    login_login: 'Login',
    login_registration: 'Registration',
    login_regkey: 'Registration Key',
    login_back: 'Back to login',

    toast_email_format_wrong: "Wrong email format",
    toast_password_format_wrong: "Wrong password format. Should contain one letter uppercase, one lower, one digit and be at least 8 chars long",
    toast_text_format_wrong: "Wrong text format",

    login_toast_verified: "Your account has been successfully verified! You can enter the site",

    login_toast_not_verified: "You haven't verified your account. Please check your email and verify your account",
    login_toast_invalid_data: "Invalid login or password!",
    login_toast_confirmation_letter: "A registration confirmation letter has been sent to your mail.",
    login_toast_user_already_exists: "You entered incorrect information or the user with the this Email is already registered. Try again!",

    toast_fields_required: "Please fill all the required fields",

    profile_marquee_launched: 'Phishing launched',
    profile_marquee_finished: 'Phishing campaign completed on ',
    profile_quantity_phished: 'Quantity of employees phished',
    profile_details: 'Details',
    profile_settings: 'Profile settings',
    profile_send: 'Send',
    profile_change_email: 'Change email',
    profile_change_password: 'Change password',
    profile_change_position: 'Change position',
    profile_change_company: 'Change company',
    profile_delete_profile: 'Delete profile',
    profile_are_you_sure: 'Are you sure you want to delete your profile? After the profile is deleted, all phishing information will be permanently deleted.',
    profile_delete_hack: 'Message Delete Profile HACK',
    profile_accept: 'If you accept the terms of use of the site, you confirm that you are the head of the company / organization or an authorized person to conduct inspections of its employees. It is STRICTLY FORBIDDEN to use the site to attack outside organizations / people, to harm, steal personal data, spread spam, viral information!',
    profile_i_accept: 'I accept the terms',
    profile_fill_info: 'Fill in information about yourself',
    // placeholders
    profile_current_email: 'Your current e-mail',
    profile_new_email: 'New email',
    profile_repeat_email: 'Repeat new email',
    profile_password: 'Password',
    profile_new_password: 'New password',
    profile_repeat_password: 'Repeat new password',
    profile_new_position: 'New position',
    profile_new_company: 'New company name',
    profile_firstname: 'Your name',
    profile_lastname: 'Your last name',
    profile_position: 'Position',
    profile_company: 'Company',

    profile_toast_email_should_match: "Emails should match",
    profile_toast_email_changed: "Your email has been successfully changed! Please logout for these changes to take effect.",
    profile_toast_email_incorrect: "You entered an incorrect email or password. Try again!",
    profile_toast_password_changed: 'Password successfully changed',
    profile_toast_password_incorrect: "You entered an incorrect password. Try again!",
    profile_toast_password_should_match: "Passwords should match",
    profile_toast_position_changed: "Position has been changed",
    profile_toast_company_changed: "Company has been changed",
    profile_toast_profile_deleted: "Profile has been deleted",
    profile_toast_profile_not_deleted: "Profile has not been deleted",
    profile_toast_customerdata_changed: "Customer data changed",
    profile_toast_session_expired: "Session has been expired",

    toast_error: "Something wrong",

    // render part
    list_upload: 'Upload the file with the list of employees in CSV format.',
    list_specify: 'Specify and name the headings for the columns as shown in the sample below.',
    list_save: 'Save',
    list_delete: 'Delete',
    list_you_can_download: 'You can download an example from our website and fill it out yourself:',
    list_show_list: 'Show the list',
    list_scenarios_locked: 'The scenarios are locked cannot be edited until campaign is completed or restarted.',
    list_scenarios_view: 'View if you want to see progress on active campaign.',
    list_delete_list: 'Delete entire list',
    // component part
    list_message_empty_document: "You have uploaded an empty document",
    list_message_delete_error: "Deletion goes wrong! Please try again",
    list_message_invalid_csv: 'Invalid CSV data',
    list_message_updated_success: "Staff updated successfully.",
    list_message_updated_unsuccess: "Staff failed to update successfully.",
    list_message_upload_success: "Staff uploaded successfully.",
    list_message_upload_failed: "Staff failed to upload.",
    list_message_incorrect_csv: "Incorrect CSV format or missing columns. expected: first_name, second_name, email, department",
    list_message_deleted_success: "Staff List Deleted Successfully.",
    list_message_deleted_not_deleted: "Staff List failed to delete successfully",
    list_message_member_deleted: "Staff member deleted successfully.",
    list_message_member_not_deleted: "Staff member failed to delete successfully.",
    list_message_staff_download: "Staff list downloaded successfully.",
    list_message_staff_not_download: "Staff list failed to download successfully.",

    // render
    reports_you_want: 'Do you want to sort reports by the date the message was opened?',
    reports_sorting_incorrect: 'The sorting period selected is incorrect',
    reports_sort_by_script: 'Sort by script:',
    reports_sort_by_department: 'Sort by department:',
    reports_search: 'Search',
    reports_general_statistics: 'General statistics',
    reports_successful_statistics: 'Statistics of successful phishing incidents',
    reports_people_tested: 'People tested',
    reports_emails_sent: 'Emails sent',
    reports_successful_cases: 'Total number of successful cases',
    reports_ignored_letters: 'Ignored letters',
    reports_download_xls: 'Download as XLS',
    // component
    reports_no_reports_yet: "You currently don't have an active campaign yet. The reports will appear here after you launch your campaign. This is currently locked until the campaign starts.",
    reports_results_found: "Results Found, Loading Results.",
    reports_no_info: "there is no information on the selected parameters",

    scenary_scenario_saved: "Scenario saved successfully",
    scenary_scenario_notsaved: "Scenario failed to save successfully",
    scenary_document_editable: "Document is editable",
    scenary_back_to_scripts: "Back to scripts",
    scenary_mandatory: "MANDATORY!",
    scenary_can_edit: "You can edit the text body of the letter, but remember that the following words are reserved!",
    scenary_and: "and",
    scenary_start_of: "start of custom html block",
    scenary_end_of: "end of custom html block",
    scenary_or_if: "or if you have a custom attachment you may use ",
    scenary_name_of: "name of your attache;",
    scenary_when_the_actions: " When the letters are sent, the words will be replaced with links and may perform additional actions like including attachments. Links that are clicked or opened are used to track the actions of the recipient of the letter. If you delete the reserved words, the recipient might receive an incorrect letter or a letter that will make it impossible to track phishing.",
    scenary_enter_email: "Please enter correct email",
    scenary_popup_message: "Are you sure you want to Save? This will overwrite the previous Email template.",
    scenary_edit: "Edit",
    scenary_popup_save: "Save",
    scenary_popup_cancel: "Cancel",

    scenarios_you_have: "You have already started the phishing process. Because of this, script texts are not available for editing.",
    scenarios_launch: "LAUNCH PHISHING",
    scanarios_to_run: "to run the scenario.",
    scenarios_if_you: "If you have already run the scenario, the results will appear in the section ",
    scenarios_reports: 'REPORTS',
    scenarios_soon: "soon",
    scenarios_end_of: " end of custom html block When the letters are sent, the words will be replaced with links and may perform additional actions like including attachments. Links that are clicked or opened are used to track the actions of the recipient of the letter. Be sure to kee included in your email one of reserved keywords for tracking.",

    fake_password_images_updated: "Images Updated Successfully",
    fake_password_images_notupdated: "Images Failed To Update Successfully",
    fake_password_images_deleted: "Images Deleted Successfully",
    fake_password_images_notdeleted: "Images Failed To Deleted Successfully",
    fake_password_images_saved: "Images Saved Successfully",
    fake_password_images_notsaved: "Images Failed To Save Successfully",
    fake_password_this_window: "This download window will not be displayed to the recipient of the letter.",
    fake_password_the_page: "The page will be updated",
    fake_password_upload_logo: "Upload your logo",
    fake_password_here: 'here',
    fake_password_upload_background: "Load your background",
    fake_password_password_change: "Password change",
    fake_password_please_confirm: "Please confirm your details for the password change",
    fake_login_sso: "SSO Portal",
    fake_login_please_login: "Please login into the company portal",

}