import React from "react";
import icon1 from "../../../assets/images/mainpage/steps/icon1.png";
import icon2 from "../../../assets/images/mainpage/steps/icon2.png";
import icon3 from "../../../assets/images/mainpage/steps/icon3.png";
import icon4 from "../../../assets/images/mainpage/steps/icon4.png";
import icon5 from "../../../assets/images/mainpage/steps/icon5.png";
import {FormattedMessage} from "react-intl";

const Steps = () => {
  return (
    <div className="steps container__fixed ">
      <h2><FormattedMessage id="mp_steps_how"/></h2>
      <div className="steps__top layout">
        <div className="steps__container">
          <h3>1</h3>
          <div className="steps__round">
            <img src={icon1} className="icon__img" alt="icon" />
          </div>
          <h4 className="steps_smalltext"><FormattedMessage id="mp_steps_registration"/></h4>
        </div>
        <div className="steps__container">
          <h3>2</h3>
          <div className="steps__round">
            <img src={icon3} className="icon__img icon__img-with-pen" alt="icon" />
          </div>
          <h4 className="steps_smalltext">
            <FormattedMessage id="mp_steps_load"/>
          </h4>
        </div>
        <div className="steps__container">
          <h3>3</h3>
          <div className="steps__round">
            <img src={icon2} className="icon__img icon__img-with-pen" alt="icon" />
          </div>
          <h4 className="steps_smalltext"><FormattedMessage id="mp_steps_scenario"/></h4>
        </div>
      </div>

      <div className="steps__bottom layout">
        <div className="steps__container">
          <h3>4</h3>
          <div className="steps__round">
            <img src={icon5} className="icon__img" alt="icon" />
          </div>
          <h4 className="steps_smalltext"><FormattedMessage id="mp_steps_launch"/></h4>
        </div>
        <div className="steps__container">
          <h3>5</h3>
          <div className="steps__round">
            <img src={icon4} className="icon__img" alt="icon" />
          </div>
          <h4 className="steps_smalltext"><FormattedMessage id="mp_steps_getting"/></h4>
        </div>
      </div>
    </div>
  );
};

export default Steps;
