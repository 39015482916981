import {LOCALES} from "./locales";

export const DEFAULTLANGUAGE = "en";
export let CURRENTLANGUAGE = DEFAULTLANGUAGE
export const LANGUAGES = LOCALES

export default {
  DEFAULTLANGUAGE,
  CURRENTLANGUAGE,
  LANGUAGES
};