import React from "react";
import {FormattedMessage} from "react-intl";

const Damages = () => {
  return (
    <div className="damages container__fixed ">
      <h2><FormattedMessage id="mp_damages_why"/></h2>
      <div className="damages__blocks_container layout ">
        <div className="damages__block">
          <div className="damages__block_top">
            <h2> >90% </h2>
          </div>
          <div className="damages__block_bottom damages__block_bottom_first">
            <p className="damages__block__text"><FormattedMessage id="mp_damages_ofsuccessful"/></p>
          </div>
        </div>

        <div className="damages__block">
          <div className="damages__block_top">
            <h2> 15% </h2>
          </div>
          <div className="damages__block_bottom">
            <p className="damages__block__text"><FormattedMessage id="mp_damages_ofphished"/></p>
          </div>
        </div>

        <div className="damages__block">
          <div className="damages__block_top">
            <h2> 1 <FormattedMessage id="mp_damages_of"/> 130 </h2>
          </div>
          <div className="damages__block_bottom">
            <p className="damages__block__text"><FormattedMessage id="mp_damages_emails"/></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Damages;
