import React, {useEffect} from "react";
import {useState} from "react";
import Popup from "../../../../src/components/popup/popup.js";
import cookie from "react-cookies";
import axios from "axios";
import { NotificationComponent } from "../../functions/functions";
import {toast} from "react-toastify"

export const DangerZone = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [urlEndpoint, setUrlEndpoint] = useState("");

    const sendOverride = () => {
        let data = JSON.stringify({});
        axios
          .post(process.env.REACT_APP_API_PATH + urlEndpoint, data, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                toast.success("success");
                console.log("[+] Override executed :" + urlEndpoint);
                props.isCampaignFinishedFunc();
                props.isScenarioActiveFunc();
              } else if (response.data.status === "failed") {
                toast.warning("danger");
                console.log("[-] Override Failed in backend");
                props.isCampaignFinishedFunc();
                props.isScenarioActiveFunc();
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
        setShowPopup(!showPopup)
    }

    const showBPopup = () =>{
        setShowPopup(!showPopup)
    }

    return (
        <>
            <NotificationComponent position="top-right"/>
            <div className="DangerZone">
                <div className="warning">
                    <h5 className="bold">DANGERZONE!</h5>
                </div>
                <div>
                    {showPopup ? (
                        <Popup
                            TextMessage={showMessage}
                            okFunction={sendOverride}
                            okMessage="Execute"
                            cancelMessage="Cancel"
                            cancelFunction={showBPopup}
                        />
                    ) : null}
                </div>
                <div className="warning-information-block">
                    <div className="warning-information-block_bottom">
                        <p>Only execute these functions if you really understand what you are doing. </p>
                    </div>
                </div>

                <div className="information-section">
                    <div className="information-block">
                        <div className="information-block_bottom">
                            <p>
                                This will allow you to stop the phishing campaign if something isn't working. You may
                                continue by relaunching the campaign. until restart, you will lose access to reports.
                                Stopping the
                                campaign, does <strong>NOT</strong> reset any reporting information.
                            </p>
                        </div>
                    </div>
                    <button
                        className="show-reports"
                        onClick={() => {
                            showBPopup();
                            setUrlEndpoint("stopcampaign");
                            setShowMessage("Are you sure you want to stop the campaign?")
                        }}
                    >
                        Stop Campaign
                    </button>
                </div>

                <div className="information-section">
                    <div className="information-block">
                        <div className="information-block_bottom">
                            <p>This will allow you to reset the phishing campaign and restart from the begining. All
                                reporting information will be set to 0 and you will be able to start the campaign
                                anew.</p>
                        </div>
                    </div>
                    <button
                        className="show-reports"
                        onClick={() => {
                            showBPopup()
                            setUrlEndpoint("resetcampaign");
                            setShowMessage("Are you sure you want to reset the campaign?")
                        }}
                    >
                        Reset Campaign
                    </button>
                </div>
            </div>
        </>
    )
}