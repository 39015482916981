import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import "./css/mainpage.css";
import classNames from "classnames";
import Firstscreen from "./firstscreen/Firstscreen";
import Phishing from "./phishing/Phishing";
import Damages from "./damages/Damages";
import Humanrisks from "./humanrisks/Humanrisks";
import Aboutsite from "./aboutsite/Aboutsite";
import Steps from "./steps/Steps";
import Footer from "./Footer";
import translationsConfig from "../../translations/translationsConfig";
import {FormattedMessage} from 'react-intl'

export default function Mainpage(props){
    const [menuOpened, setMenuOpened] = useState(false);
    const [buttonBlock, setButtonBlock] = useState(false);

    const [lastScroll, setLastScroll] = useState(0)
    const [isScrolled, setIsScrolled] = useState(false);

    const openMenu = () => setMenuOpened(true);
    const closeMenu = () => setMenuOpened(false);

    useEffect(() => {
        window.addEventListener("scroll", scrollPage);
        let elements = document.querySelectorAll(".menu__item");
        for (let i = 0; i < elements.length; i++) {
          elements[i].onclick = function () {
              closeMenu();
          };
        }

        return (
            window.removeEventListener("scroll", scrollPage)
        )
    })

    useEffect(() => {
        const handleScroll = () => {
            const actualScroll = window.pageYOffset;

            if(actualScroll > lastScroll){
                setIsScrolled(true)
            } else if(actualScroll < lastScroll){
                setIsScrolled(false)
            }

            setLastScroll(actualScroll)
        }

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [window.pageYOffset]);

    const scrollPage = () => {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setButtonBlock(true);
        } else {
            setButtonBlock(false);
        }
    }

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <>
            <div className="Mainpage">
                <div className={classNames("overlay", {open: menuOpened})} onClick={closeMenu}></div>
                <div className="navbar">
                  <div className="hamburger-menu hamburger-menu_en">
                      <button className={classNames("hamburger-menu-btn hamburger-menu-btn_open", {open: !menuOpened})} onClick={openMenu}>
                        <i className="fas fa-bars"></i>
                      </button>
                      <button className={classNames("hamburger-menu-btn hamburger-menu-btn_close", {open: menuOpened})} onClick={closeMenu}>
                        <i className="fas fa-times"></i>
                      </button>

                      <ul className={classNames("menu__box", {open: menuOpened})}>
                        <li className="menu__item">
                          <NavLink to="/"><FormattedMessage id="mp_main_page"/></NavLink>
                        </li>
                        <li className="menu__item">
                          <a href="#phishing__scroll"><FormattedMessage id="mp_about_phishing"/></a>
                        </li>
                        <li className="menu__item">
                          <a href="#aboutSite__scroll"><FormattedMessage id="mp_about_website"/></a>
                        </li>
                          <li className="menu__item">
                              <NavLink to="/login">
                                  {" "}
                                  <span>
                              <i className="fas fa-sign-in-alt"></i><FormattedMessage id="mp_login"/>
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                </div>

                <div className={classNames("navbar__menu navbar-desktop layout container__fixed", {'navbar-hide': isScrolled})}>
                    <ul className="layout navbar__menu_list">
                        <li>
                            <NavLink to="/"><FormattedMessage id="mp_main_page"/></NavLink>
                        </li>
                        <li>
                            <a href="#phishing__scroll"><FormattedMessage id="mp_about_phishing"/></a>
                        </li>
                        <li>
                            <a href="#aboutSite__scroll"><FormattedMessage id="mp_about_website"/></a>
                        </li>
                    </ul>
                    <div className="navbar-button-menu layout">
                        <span className="language-changer" onClick={props.changeLanguage}>{translationsConfig.CURRENTLANGUAGE}</span>
                        <NavLink to="/login" className="navbar__login-link ">
                            {" "}
                            <span>
                        <i className="fas fa-sign-in-alt"></i><FormattedMessage id="mp_login"/>
                      </span>
                        </NavLink>
                    </div>
                </div>

                <Firstscreen/>
                <div id="phishing__scroll"></div>
                <Phishing/>
                <Humanrisks/>
                <Damages/>
                <div id="aboutSite__scroll"></div>
                <Aboutsite/>
                <Steps/>
                <Footer/>
                <button onClick={topFunction} id="myBtn" title="Go to top" style={{display: buttonBlock ? 'block' : 'none'}}>
                    <i className="fas fa-arrow-up"></i>
                </button>
            </div>
        </>
    )
}