import React, {useRef} from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import DatePicker from "react-datepicker";
import Chart from "react-apexcharts";
import {FormattedMessage} from "react-intl";
import classNames from 'classnames'
import './Reports.css'

export const ReportsBody = (props) => {
    const tableRef = useRef(null)

    return (
        <>
            <div className="reports__container ">
                <div className="reports__container__options">
                    <div className="label-for-dateCheked">
                        <label htmlFor="dateCheked"><FormattedMessage id="reports_you_want"/></label>
                        <input type="checkbox" id="dateCheked" name="dateCheked" value="dateCheked" ref={props.checkbox}
                               onChange={props.onChangeCheckBox}/>
                    </div>
                    {props.isDateCorrect === false ?
                        <div className="errorMessage"><FormattedMessage id="reports_sorthing_incorrect"/></div> : null}
                    <div className="reports__container__calendar__wraper  layout">
                        <div className={classNames("reports__container__calendar__from", {'fotn-disabled': props.fontDisabled})}>
                            <label>From:</label>
                            <br/>
                            <DatePicker selected={props.startDateFrom} onChange={props.handleChangeFrom}
                                        locale="en" dateFormat="dd/MM/yyyy" disabled={props.datePickerDisabled}
                            />
                        </div>
                        <div className={classNames("reports__container__calendar__to", {'fotn-disabled': props.fontDisabled})}>
                            <label>To:</label>
                            <br/>
                            <DatePicker selected={props.startDateTo} onChange={props.handleChangeTo}
                                        locale="en" dateFormat="dd/MM/yyyy" disabled={props.datePickerDisabled}/>
                        </div>
                    </div>

                    <div className="reports__container__sort__wraper layout">
                        <div className="reports__container__sort-form">
                            <label><FormattedMessage id="reports_sort_by_script"/></label>
                            <br/>
                            {props.scenarios ? (
                                <select
                                    name="attack_type"
                                    className="attack_type"
                                    //  ref={attack_type}
                                    onChange={props.updateAttackValue}
                                >
                                    <option value="all" defaultValue>
                                        All
                                    </option>
                                    <option value="scenariosubject1">{props.scenarios[0].scenariosubject1}</option>
                                    <option value="scenariosubject2">{props.scenarios[0].scenariosubject2}</option>
                                    <option value="scenariosubject3">{props.scenarios[0].scenariosubject3}</option>
                                </select>
                            ) : null}
                        </div>

                        <div className="reports__container__sort-form">
                            <label><FormattedMessage id="reports_sort_by_department"/></label>
                            <br/>
                            {props.departments ? (
                                <select
                                    name="department-type"
                                    className="department-type"
                                    // ref={department_type}
                                    onChange={props.updateDefenseValue}
                                >
                                    <option value="all" defaultValue>
                                        All
                                    </option>
                                    {props.departments.map((data, id) => (
                                        <option value={data.department} key={id}>
                                            {data.department}
                                        </option>
                                    ))}
                                </select>
                            ) : null}
                        </div>
                    </div>
                    <button type="submit" className="submit__btn btn_search reports__container__options-submit__btn"
                            value="Submit" onClick={props.searchInformation}>
                        <FormattedMessage id="reports_search"/>
                    </button>
                </div>
                {props.message ? <p>{props.message}</p> : null}

                {props.informationArr ? (
                    <div className="chart-and-shot-statistic_contaigner ">
                        <div className="donuts_contaigner layout">
                            {(props.datePickerDisabled === false && props.Attack_typeActive === true && props.Department_typeActive === false) ||
                            (props.datePickerDisabled === false && props.Attack_typeActive === false && props.Department_typeActive === true) ||
                            (props.datePickerDisabled === false && props.Attack_typeActive === true && props.Department_typeActive === true) ||
                            (props.datePickerDisabled === false && props.Attack_typeActive === false && props.Department_typeActive === false) ? null : (
                                <div className="donut donatGeneral">
                                    <div>
                                        <h5><FormattedMessage id="reports_general_statistics"/></h5>
                                        {props.bigMobile === false ? (
                                            <Chart options={props.options.options} series={props.series}
                                                   labels={props.options.labels} type="donut" width="250"/>
                                        ) : (
                                            <Chart options={props.options.options} series={props.series}
                                                   labels={props.options.labels} type="donut" width="350"/>
                                        )}
                                    </div>
                                </div>
                            )}
                            {(props.datePickerDisabled === true && props.Attack_typeActive === true && props.Department_typeActive === false) ||
                            (props.datePickerDisabled === true && (props.Attack_typeActive === true) && (props.Department_typeActive === true)) ||
                            (props.datePickerDisabled === false && (props.Attack_typeActive === true) && (props.Department_typeActive === false)) ||
                            (props.datePickerDisabled === false && (props.Attack_typeActive === true) && (props.Department_typeActive === true)) ? null : (
                                <div className="donut donatCases">
                                    {props.series2[0] > 0 || props.series2[1] > 0 || props.series2[0] > 0 ? (
                                        <div>
                                            <h5><FormattedMessage id="reports_successful_statistics"/></h5>
                                            {props.bigMobile === false ? (
                                                <Chart options={props.options2} series={props.series2}
                                                       labels={props.labels} type="donut" width="250"/>
                                            ) : (
                                                <Chart options={props.options2} series={props.series2}
                                                       labels={props.labels} type="donut" width="350"/>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>

                        <table className="staff-list-table reports-shot-statistic-table">
                            <tbody>
                            {props.datePickerDisabled === false ? null : (
                                <tr>
                                    <th><FormattedMessage id="reports_people_tested"/></th>
                                    <td>{props.allStaff}</td>
                                </tr>
                            )}
                            {props.datePickerDisabled === false ? null : (
                                <tr>
                                    <th><FormattedMessage id="reports_emails_sent"/></th>
                                    <td>{props.sensedLeters}</td>
                                </tr>
                            )}
                            {props.Attack_typeActive === true ? null : (
                                <tr>
                                    <th>{props.scenarios[0].scenariosubject1}</th>
                                    <td>{props.opened_links}</td>
                                </tr>
                            )}
                            {props.Attack_typeActive === true ? null : (
                                <tr>
                                    <th>{props.scenarios[0].scenariosubject2}</th>
                                    <td>{props.opened_attachment}</td>
                                </tr>
                            )}
                            {props.Attack_typeActive === true ? null : (
                                <tr>
                                    <th>{props.scenarios[0].scenariosubject3}</th>
                                    <td>{props.logined}</td>
                                </tr>
                            )}
                            {props.datePickerDisabled === false ? null : (
                                <tr>
                                    <th><FormattedMessage id="reports_successful_cases"/></th>
                                    <td>{props.allCachedCases}</td>
                                </tr>
                            )}
                            {props.datePickerDisabled === false ? null : (
                                <tr>
                                    <th><FormattedMessage id="reports_ignored_letters"/></th>
                                    <td>{props.uncatched}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                ) : null}

                {props.informationArr ? (
                    <div>

                        <DownloadTableExcel
                            filename="tablexls"
                            sheet="tablexls"

                            currentTableRef={tableRef.current}>

                           <button className="download-table-xls-button"><FormattedMessage id="reports_download_xls"/></button>
                        </DownloadTableExcel>

                        <div className="reports-information">
                            <table className="staff-list-table staff-list-table_reports" id="table-to-xls" ref={tableRef}>
                                <thead>
                                {props.scenarioFiltered ? (
                                    <tr>
                                        <th>№</th>
                                        <th>last name</th>
                                        <th>name</th>
                                        <th>email</th>
                                        <th>department</th>
                                        <th>scenario</th>
                                        <th className="th_sended-letters" data-title="Emails sent">
                                            <i className="fas fa-paper-plane"></i>
                                        </th>
                                        <th>phished</th>
                                        <th>№</th>
                                    </tr>
                                ) : (
                                    <tr>
                                        <th>№</th>
                                        <th>last name</th>
                                        <th>name</th>
                                        <th>email</th>
                                        <th>department</th>
                                        <th>scenario</th>
                                        {props.datePickerDisabled === true ? (
                                            <th className="th_sended-letters" data-title="Emails sent">
                                                <i className="fas fa-paper-plane"></i>
                                            </th>
                                        ) : null}
                                        <th>phished</th>
                                        <th>scenario</th>
                                        {props.datePickerDisabled === true ? (
                                            <th data-title="Emails sent" className="th_sended-letters">
                                                <i className="fas fa-paper-plane"></i>
                                            </th>
                                        ) : null}
                                        <th>phished</th>
                                        <th>scenario</th>
                                        {props.datePickerDisabled === true ? (
                                            <th className="th_sended-letters" data-title="Emails sent">
                                                <i className="fas fa-paper-plane"></i>
                                            </th>
                                        ) : null}
                                        <th>phished</th>
                                        <th>№</th>
                                    </tr>
                                )}
                                </thead>

                                {props.scenarioFiltered ? (
                                    <tbody>
                                    {props.informationArr.map((data, key) => (
                                        <tr key={data.staffid} className={"staff-row staff-row-" + data.staffid}>
                                            <td>{key + 1}</td>
                                            <td>{data.lastname}</td>
                                            <td>{data.firstname}</td>
                                            <td>{data.email}</td>
                                            <td>{data.department}</td>

                                            {data.scenariosubject1 ? <td>{data.scenariosubject1}</td> : null}

                                            {data.scenariosubject1 ?
                                                <td>{data.scenariosent1 ? <span>+</span> : <span>-</span>} </td> : null}

                                            {data.date1 ?
                                                <td>{props.timestampToDate(Date.parse(data.date1))}</td> : data.scenariosubject1 ?
                                                    <td></td> : null}

                                            {data.scenariosubject2 ? <td>{data.scenariosubject2}</td> : null}
                                            {data.scenariosubject2 ?
                                                <td>{data.scenariosent2 ? <span>+</span> : <span>-</span>}</td> : null}

                                            {data.date2 ?
                                                <td>{props.timestampToDate(Date.parse(data.date2))}</td> : data.scenariosubject2 ?
                                                    <td></td> : null}

                                            {data.scenariosubject3 ? <td>{data.scenariosubject3}</td> : null}
                                            {data.scenariosubject3 ?
                                                <td>{data.scenariosent3 ? <span>+</span> : <span>-</span>}</td> : null}
                                            {data.date3 ?
                                                <td>{props.timestampToDate(Date.parse(data.date3))}</td> : data.scenariosubject3 ?
                                                    <td></td> : null}
                                            <td>{key + 1}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                    {props.informationArr.map((data, key) => (
                                        <tr key={data.staffid} className={"staff-row staff-row-" + data.staffid}>
                                            <td>{key + 1}</td>
                                            <td>{data.lastname}</td>
                                            <td>{data.firstname}</td>
                                            <td>{data.email}</td>
                                            <td>{data.department}</td>

                                            {data.scenariosubject1 ? (
                                                <td>
                                                    <div className="subject-overflow">{data.scenariosubject1}</div>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}
                                            {props.datePickerDisabled === true && data.scenariosubject1 ?
                                                <td>{data.scenariosent1 ? <span>+</span> : <span>-</span>}</td> : null}
                                            {data.scenariosubject1 ?
                                                <td>{data.date1 ? props.timestampToDate(Date.parse(data.date1)) : null}</td> :
                                                <td></td>}

                                            {data.scenariosubject2 ? (
                                                <td>
                                                    <div className="subject-overflow">{data.scenariosubject2}</div>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}

                                            {props.datePickerDisabled === true && data.scenariosubject2 ?
                                                <td>{data.scenariosent2 ? <span>+</span> : <span>-</span>}</td> : null}

                                            {data.scenariosubject2 ?
                                                <td>{data.date2 ? props.timestampToDate(Date.parse(data.date2)) : null}</td> :
                                                <td></td>}

                                            {data.scenariosubject3 ? (
                                                <td>
                                                    <div className="subject-overflow">{data.scenariosubject3}</div>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}

                                            {props.datePickerDisabled === true && data.scenariosubject3 ?
                                                <td>{data.scenariosent3 ? <span>+</span> : <span>-</span>}</td> : null}

                                            {data.scenariosubject3 ?
                                                <td>{data.date3 ? props.timestampToDate(Date.parse(data.date3)) : null}</td> :
                                                <td></td>}
                                            <td>{key + 1}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                )}
                            </table>
                        </div>

                        <div></div>
                    </div>
                ) : null}
            </div>
        </>
    )
}