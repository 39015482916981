import {NotificationComponent} from "../../functions/functions";
import CSVReader from "react-csv-reader";
import {CSVToHTMLTable} from "./CSVToHTMLTable";
import Pagination from "react-js-pagination";
import Popup from "../../popup/popup";
import classNames from 'classnames';
import React, { useRef, useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import {FormattedMessage} from "react-intl";

import {Loader} from "../Loader";


export const Render = (props) => {

    return (
      <>
          <NotificationComponent position="top-right"/>
          <div className="List">
              {props.staffListLoaded ? <div className="list-container">
                  <div>
                      {props.arrayStaff.length === 0 ? (
                          <div className="List">
                              <div className="list-container">
                                  <div>
                                      {props.isFileUploaded === false ? (
                                          <div>
                                              <div>
                                                  <p><FormattedMessage id="list_upload"/></p>
                                                  <CSVReader
                                                      cssClass="csv-reader-input"
                                                      label="Upload CSV file"
                                                      onFileLoaded={props.handleForce}
                                                      parserOptions={props.papaparseOptions}
                                                      inputId="scvUploader"
                                                      inputStyle={{color: "red"}}
                                                  />

                                                  <p><FormattedMessage id="list_specify"/></p>
                                                  <div className="staff-list-table_example_contaigner">
                                                      <Table className="staff-list-table staff-list-table_example">
                                                          <thead>
                                                          <tr className="staff-row staff-row_header">
                                                              <th>first name</th>
                                                              <th>last name</th>
                                                              <th>email</th>
                                                              <th>department</th>
                                                          </tr>
                                                          </thead>
                                                          <tbody>
                                                          <tr>
                                                              <td className="staff-information_cell">Jone</td>
                                                              <td className="staff-information_cell">Jonson</td>
                                                              <td className="staff-information_cell">jonjonsone@gmail.com</td>
                                                              <td className="staff-information_cell">qa</td>
                                                          </tr>
                                                          <tr>
                                                              <td className="staff-information_cell">Anna</td>
                                                              <td className="staff-information_cell">Green</td>
                                                              <td className="staff-information_cell">annagreen@gmail.com</td>
                                                              <td className="staff-information_cell">project manager
                                                              </td>
                                                          </tr>
                                                          </tbody>
                                                      </Table>
                                                  </div>

                                                  <div className="download-file-from-db">
                                                      <p><FormattedMessage id="list_you_can_download"/></p>
                                                      {props.file ?
                                                          <button className="btn-download-file-from-db" onClick={() => {
                                                              props.downloadRef.current.click()
                                                          }}>
                                                              <a href={props.file} download="example.csv"
                                                                 style={{visibility: "hidden"}}
                                                                 ref={props.downloadRef}></a>
                                                              <i className="fas fa-file-csv"></i>
                                                          </button>
                                                          :
                                                          null
                                                      }
                                                  </div>
                                              </div>
                                          </div>
                                      ) : null}

                                      <p className="errorMassage">{props.errorMessage}</p>

                                      {props.csvData ? (
                                          <div className="csv-table-container">
                                              <CSVToHTMLTable csvData={props.csvData}
                                                              tableClassName="table csv-table table-striped table-hover"
                                                              tdClassName="staff-information_cell"/>
                                          </div>
                                      ) : null}

                                      {props.csvData && props.isFileUploaded === false ? (
                                          <div className="btn-for-staff-table_contaigner layout ">
                                              <button className="btn-save-csv-staff btn-for-staff-table"
                                                      onClick={props.uploadStaff}>
                                                  <FormattedMessage id="list_save"/>
                                              </button>
                                              <button
                                                  className="btn-save-csv-staff btn-for-staff-table  btn-for-staff-table_delet"
                                                  onClick={props.cancelUpload}>
                                                  <FormattedMessage id="list_delete"/>
                                              </button>
                                          </div>
                                      ) : null}
                                      {props.isFileUploaded === true ? (
                                          <button className="btn btn-for-staff-table btn-for-staff-table_next"
                                                  id="buttonShowList" onClick={props.showStaffList}>
                                              <FormattedMessage id="list_show_list"/>
                                          </button>
                                      ) : null}
                                  </div>
                              </div>
                          </div>
                      ) : props.arrayStaff.length > 0 ? (
                          <div className="List">
                              <div className="list-container">
                                  <div>
                                      {props.smallMobile === true ? (
                                          <Pagination activePage={props.activePage} itemsCountPerPage={10}
                                                      totalItemsCount={props.pagesCount} pageRangeDisplayed={2}
                                                      onChange={props.handlePageChange}/>
                                      ) : (
                                          <Pagination activePage={props.activePage} itemsCountPerPage={10}
                                                      totalItemsCount={props.pagesCount} pageRangeDisplayed={3}
                                                      onChange={props.handlePageChange}/>
                                      )}

                                      {props.isScenarioActive ? (
                                          <div className="information-block">
                                              <div className="startphishing-warning-about-empty-stafflist errorMassage">
                                                  <p>
                                                      <FormattedMessage id="list_scenarios_locked"/>
                                                      <br/>
                                                      <FormattedMessage id="list_scenarios_view"/>
                                                  </p>
                                              </div>
                                          </div>
                                      ) : null}

                                      <div className="staff-list-table_contaigner">
                                          <Table className="staff-list-table">
                                              <thead>
                                              <tr className="staff-row staff-row_header" key={props.staffid}>
                                                  <th></th>
                                                  <th></th>
                                                  <th></th>
                                                  <th>Name</th>
                                                  <th>Last name</th>
                                                  <th>Е-mail</th>
                                                  <th>Department</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {props.arrayStaff.map((data, idx) => (
                                                  <tr key={data.staffid} className={"staff-row"}>
                                                      <td className={classNames({"staff-list-table-btn btn-delete": !props.isScenarioActive})}>
                                                          <a className={classNames('fas lg fa-trash-alt',
                                                              {
                                                                  'enabled-btn': props.trashButtonStatus,
                                                                  'disabled-btn': props.isScenarioActive
                                                              })}
                                                             onClick={!props.isScenarioActive ? () => props.deleteOneStaff(data.staffid) : undefined}></a>
                                                      </td>
                                                      <td className={classNames({"staff-list-table-btn btn-red": !props.isScenarioActive})}>
                                                          <a className={classNames(`fas fa-pen `, {
                                                              'enabled-btn': props.penButtonStatus === 'enabled-btn',
                                                              'disabled-btn': props.penButtonStatus === 'disabled-btn' || props.isScenarioActive
                                                          })} onClick={!props.isScenarioActive ? () => {
                                                              props.setEditableRowId(data.staffid)
                                                              props.setpenButtonStatus(prevId => prevId === data.staffid ? "disabled-btn" : "enabled-btn");
                                                          } : undefined}></a></td>
                                                      <td className={classNames({"staff-list-table-btn btn-save": !props.isScenarioActive})}>
                                                          <a className={classNames('fas fa-save', {
                                                              'enabled-btn': props.editableRowId === data.staffid,
                                                              'disabled-btn': props.editableRowId !== data.staffid || props.isScenarioActive
                                                          })} onClick={() => props.editableRowId === data.staffid ? props.handleSave(data.staffid) : null}></a></td>
                                                      <td className={classNames(`${props.editableRowId === data.staffid ? 'writable' : 'not-writable'}`)}>
                                                          <Form.Control
                                                              ref={props.inputRefs.current[data.staffid]?.firstname}
                                                              type="text" defaultValue={data.firstname}
                                                              readOnly={props.editableRowId !== data.staffid}
                                                              disabled={props.editableRowId !== data.staffid}/></td>
                                                      <td className={classNames(`${props.editableRowId === data.staffid ? 'writable' : 'not-writable'}`)}>
                                                          <Form.Control
                                                              ref={props.inputRefs.current[data.staffid]?.lastname}
                                                              type="text" defaultValue={data.lastname}
                                                              readOnly={props.editableRowId !== data.staffid}
                                                              disabled={props.editableRowId !== data.staffid}/></td>
                                                      <td className={classNames(`${props.editableRowId === data.staffid ? 'writable' : 'not-writable'}`)}>
                                                          <Form.Control
                                                              ref={props.inputRefs.current[data.staffid]?.email}
                                                              type="text" defaultValue={data.email}
                                                              readOnly={props.editableRowId !== data.staffid}
                                                              disabled={props.editableRowId !== data.staffid}/></td>
                                                      <td className={classNames(`${props.editableRowId === data.staffid ? 'writable' : 'not-writable'}`)}>
                                                          <Form.Control
                                                              ref={props.inputRefs.current[data.staffid]?.department}
                                                              type="text" defaultValue={data.department}
                                                              readOnly={props.editableRowId !== data.staffid}
                                                              disabled={props.editableRowId !== data.staffid}/></td>
                                                  </tr>
                                              ))}
                                              </tbody>
                                          </Table>
                                      </div>
                                      {props.showPopup ? (
                                          <Popup
                                              TextMessage={props.showMessage}
                                              okFunction={props.deleteAllStaff}
                                              okMessage="Execute"
                                              cancelMessage="Cancel"
                                              cancelFunction={() => {
                                                  props.setShowPopup(!props.showPopup)
                                              }}
                                          />
                                      ) : null}

                                      {props.isScenarioActive === false ? (
                                          <button
                                              className="btn btn-delete-list "
                                              onClick={() => {
                                                  props.setShowPopup(!props.showPopup)
                                                  props.setShowMessage("Are you sure you want delete all staff?")
                                              }}
                                          >
                                              <FormattedMessage id="list_delete_list"/>
                                          </button>
                                      ) : (
                                          <button className="btn btn-delete-list not-active-btn" disabled>
                                              <FormattedMessage id="list_delete_list"/>
                                          </button>
                                      )}
                                  </div>
                              </div>
                          </div>
                      ) : (
                          <div className="Profile profile-container">
                              <div className="loader_contaigner">
                                  <div className="loader"></div>
                              </div>
                          </div>
                      )}
                  </div>
              </div>
              :
              <Loader/>
              }
          </div>
      </>
    )
}
