import {useEffect, useRef, useState} from "react";
import React from "react";
import cookie from "react-cookies";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import {toast} from "react-toastify";
import {NotificationComponent} from "../../functions/functions";

import {ReportsBody} from "./ReportsBody";
import {useIntl} from "react-intl";

export const Reports = (props) => {
    const [scenarioActive, setScenarioActive] = useState(false);
    const [startDateFrom, setStartDateFrom] = useState(new Date);
    const [startDateTo, setStartDateTo] = useState(new Date);
    const [isDateCorrect, setIsDateCorrect] = useState(true);
    const [departments, setDepartments] = useState(null);
    const [scenarios, setScenarios] = useState(null);
    const [informationArr, setInformationArr] = useState(null);
    const [scenarioFiltered, setScenarioFiltered] = useState(false);
    const [message, setMessage] = useState(null);
    const [allStaff, setAllStaff] = useState(null);
    const [sensedLetters, setSensedLetters] = useState(null);
    const [openedLinks, setOpenedLinks] = useState(null);
    const [openedAttachment, setOpenedAttachment] = useState(null);
    const [logined, setLogined] = useState(null);
    const [datePickerDisabled, setDatePickerDisabled] = useState(true);
    const [attackTypeActive, setAttackTypeActive] = useState(false);
    const [DepartmentTypeActive, setDepartmentTypeActive] = useState(false);
    const [bigMobile, setBigMobile] = useState(false);
    const [attackType, setAttackType] = useState('all');
    const [departmentType, setDepartmentType] = useState('all');
    const [allCachedCases, setAllCachedCases] = useState(null);
    const [uncatched, setUncatched] = useState(null);

    const [series, setSeries] = useState([]);
    const [series2, setSeries2] = useState([])
    const [options, setOptions] = useState({
        labels: ["Cases of successful phishing", "Ignored the letter"],
        options: {enabled: true},
      })
    const [options2, setOptions2] = useState([]);
    const intl = useIntl()
    const checkbox = useRef()
    const [fontDisabled, setFontDisabled] = useState(false);

    const disableFont = () => setFontDisabled(true);
    const enableFont = () => setFontDisabled(false);

    useEffect(() => {
        if(window.innerWidth >= 640){
            setBigMobile(true)
        }
        window.addEventListener("resize", handleWindowResize);
        getReports();

        return window.removeEventListener("resize", handleWindowResize);

    }, [])

    const updateAttackValue = (e) => {
        setAttackType(e.target.value);
    }

    const updateDefenseValue = (e) => {
        setDepartmentType(e.target.value)
    }

    const handleWindowResize = () => {
        if (window.innerWidth >= 640) {
            setBigMobile(true);
        }
    }

    const getReports = () => {
        axios
            .get(`${props.host}isscenarioactive`, {
                headers: {
                    "Content-Type": "application/json",
                    xtoken: cookie.load("xtoken"),
                },
            })
            .then(
                function (response) {
                        if (response.data.status === "success") {
                        const result = response.data.result
                        let active = false;

                        result.forEach((element) => {
                            if(element.active) active = true
                        })
                        setScenarioActive(active)
                        disableFont();

                        axios
                            .get(`${props.host}selectdepartmentforreportpicker`, {
                                headers: {
                                    "Content-Type": "application/json",
                                    xtoken: cookie.load("xtoken"),
                                },
                            })
                            .then(
                                function (response) {
                                    if (response.data.status === "success") {
                                        setDepartments(response.data.result);
                                    }
                                }
                            )
                            .catch(function (error) {
                                console.log(error);
                            });

                        axios
                            .get(`${props.host}selectscenariosforreportpicker`, {
                                headers: {
                                    "Content-Type": "application/json",
                                    xtoken: cookie.load("xtoken"),
                                },
                            })
                            .then(
                                function (response) {
                                    if (response.data.status === "success") {
                                        setScenarios(response.data.result)
                                    }
                                }
                            )
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                }
            )
            .catch(function (error) {
                console.log(error);
            });
        if (scenarioActive === true) {
            disableFont()
        }
    }

    const handleChangeFrom = (date) => {
        date.setHours(0, 0, 0, 0);
        setStartDateFrom(date);
    };

    const handleChangeTo = (date) => {
        date.setHours(23, 59, 59, 0);
        setStartDateTo(date);
    };

    const countInformation = (informationCount) => {
        let opened_linksArr = [];
        let opened_attachmentArr = [];
        let loginedArr = [];
        let sensedArr = [];
        informationCount.forEach(function (item, i, arr) {
          opened_linksArr.push(item.date1);
          opened_attachmentArr.push(item.date2);
          loginedArr.push(item.date3);
          sensedArr.push(item.scenariosent1, item.scenariosent2, item.scenariosent3);
        });
    
        let opened_linksFilter = opened_linksArr.filter(function (item) {
          return item !== undefined && item != null;
        });
        let opened_attachmentFilter = opened_attachmentArr.filter(function (item) {
          return item !== undefined && item != null;
        });
        let loginedFilter = loginedArr.filter(function (item) {
          return item !== undefined && item != null;
        });

        let opened_links = opened_linksFilter.length;
        let opened_attachment = opened_attachmentFilter.length;
        let logined = loginedFilter.length;

        let allCachedCases = Number(opened_links) + Number(opened_attachment) + Number(logined);

        let newSensedArr = sensedArr.filter(function (item) {
          return item !== undefined && item != null && item !== false;
        });
    
        let countNewSensedArr = newSensedArr.length;

        let uncatched = countNewSensedArr - allCachedCases;

        setAllStaff(informationCount.length);
        setSensedLetters(countNewSensedArr);
        setOpenedLinks(opened_links);
        setOpenedAttachment(opened_attachment);
        setLogined(logined);
        setAllCachedCases(allCachedCases);
        setUncatched(uncatched);
        setSeries([allCachedCases, uncatched]);
        setSeries2([opened_links, opened_attachment, logined]);

    }

    const searchInformation = () => {
        if (!scenarioActive) {
            toast.warning(intl.formatMessage({ id: 'reports_no_reports_yet' }))
        } else {
          const options2Copy = { ...options };
          const scenariosubject1 = scenarios[0].scenariosubject1;
          const scenariosubject2 = scenarios[0].scenariosubject2;
          const scenariosubject3 = scenarios[0].scenariosubject3;

          options2Copy.labels = [scenariosubject1, scenariosubject2, scenariosubject3];
          setOptions2(options2Copy);

          const Attack_type = attackType;
          const Department_type = departmentType;

          setDepartmentTypeActive(Department_type !== "all");
          setAttackTypeActive(Attack_type !== "all");

          if (datePickerDisabled === true) {
            const data = JSON.stringify({
              Attack_type: Attack_type,
              Department_type: Department_type,
            });

            axios.post(`${props.host}searchvulnerablestaff`, data, {
              headers: {
                "Content-Type": "application/json",
                xtoken: cookie.load("xtoken"),
              },
            })
            .then(response => {
              if (response.data.status === "success") {
                  toast.success(intl.formatMessage({ id: 'reports_results_found' }))
                  setInformationArr(response.data.result)
                  setScenarioFiltered(Attack_type !== "all");
                  setMessage(null);
                  countInformation(response.data.result);
              } else if (response.data.status === "failed") {
                  toast.warning(intl.formatMessage({ id: 'reports_no_info' }))
                  setInformationArr(null);
                  setMessage(intl.formatMessage({ id: 'reports_no_info' }));
              }
            })
            .catch(error => {
              console.log(error);
            });
          } else {
            if (new Date(startDateFrom) <= new Date(startDateTo)) {
                setIsDateCorrect(true);

                const data = JSON.stringify({
                StartDateFrom: startDateFrom,
                StartDateTo: startDateTo,
                Attack_type: Attack_type,
                Department_type: Department_type,
              });
            

              axios.post(`${props.host}searchvulnerablestaff`, data, {
                headers: {
                  "Content-Type": "application/json",
                  xtoken: cookie.load("xtoken"),
                },
              })
              .then(response => {
                if (response.data.status === "success") {
                  if (Attack_type !== "all") {
                      toast.success(intl.formatMessage({ id: 'reports_results_found' }))
                      setInformationArr(response.data.result);
                      setScenarioFiltered(true);
                      setMessage(null);
                  } else {
                        console.log(response.data.result);
                      setInformationArr(response.data.result);
                      setScenarioFiltered(false);
                      setMessage(null);
                  }
                  countInformation(response.data.result);
                } else if (response.data.status === "failed") {
                    toast.warning(intl.formatMessage({ id: 'reports_no_info' }))
                    setInformationArr(null);
                    setMessage(intl.formatMessage({ id: 'reports_no_info' }));
                }
              })
              .catch(error => {
                console.log(error);
              });
            } else {
              setIsDateCorrect(false);
            }
          }
        }
      };

    const timestampToDate = (ts) => {
        if (isNaN(ts)) {
          return " ";
        } else {
          let d = new Date();
          d.setTime(ts);
          return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
        }
    }

    const onChangeCheckBox = () => {
        if (checkbox.current.checked === true) {
            setDatePickerDisabled(false);
            enableFont()
        } else {
            setDatePickerDisabled(true)
            disableFont()
        }
        setInformationArr(null)
    }

    return (
        <>
            <NotificationComponent position="top-right"/>
            <div className="reports">
                {scenarioActive === true ? (
                    <ReportsBody
                        timestampToDate={timestampToDate}
                        startDateTo={startDateTo}
                        startDateFrom={startDateFrom}
                        handleChangeTo={handleChangeTo}
                        handleChangeFrom={handleChangeFrom}
                        updateDefenseValue={updateDefenseValue}
                        updateAttackValue={updateAttackValue}
                        onChangeCheckBox={onChangeCheckBox}
                        departments={departments}
                        scenarios={scenarios}
                        searchInformation={searchInformation}
                        informationArr={informationArr}
                        massage={message}
                        datePickerDisabled={datePickerDisabled}
                        Department_typeActive={DepartmentTypeActive}
                        Attack_typeActive={attackTypeActive}
                        options={options}
                        options2={options2}
                        series={series}
                        series2={series2}
                        labels={options.labels}
                        allStaff={allStaff}
                        sensedLeters={sensedLetters}
                        opened_links={openedLinks}
                        opened_attachment={openedAttachment}
                        allCachedCases={allCachedCases}
                        bigMobile={bigMobile}
                        scenarioFiltered={scenarioFiltered}
                        uncatched={uncatched}
                        isDateCorrect={isDateCorrect}
                        checkbox={checkbox}

                        fontDisabled={fontDisabled}
                    />
                ) : (
                    <div>
                        <div className="informationBlock">
                            <div className="startphishing-warning-about-empty-stafflist errorMassage">
                                <p>
                                    You currently don't have an active campaign yet. The reports will appear here after
                                    you launch your campaign. <br/>
                                    This is currently locked until the campaign starts.
                                </p>
                            </div>
                        </div>

                        <ReportsBody
                            startDateTo={startDateTo}
                            startDateFrom={startDateFrom}
                            handleChangeTo={handleChangeTo}
                            handleChangeFrom={handleChangeFrom}
                            updateDefenseValue={updateDefenseValue}
                            updateAttackValue={updateAttackValue}
                            onChangeCheckBox={onChangeCheckBox}
                            searchInformation={searchInformation}

                            departments={departments}
                            scenarios={scenarios}
                            informationArr={informationArr}
                            massage={message}
                            datePickerDisabled={datePickerDisabled}
                            Department_typeActive={DepartmentTypeActive}
                            Attack_typeActive={attackTypeActive}
                            options={options}
                            options2={options2}
                            series={series}
                            series2={series2}
                            labels={options.labels}
                            allStaff={allStaff}
                            sensedLeters={sensedLetters}
                            opened_links={openedLinks}
                            opened_attachment={openedAttachment}
                            allCachedCases={allCachedCases}
                            scenarioActive={scenarioActive}
                            bigMobile={bigMobile}
                            scenarioFiltered={scenarioFiltered}
                            uncatched={uncatched}
                            isDateCorrect={isDateCorrect}
                            checkbox={checkbox}

                            fontDisabled={fontDisabled}
                        />
                    </div>
                )}
            </div>
        </>
    );
}