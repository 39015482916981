import React, {useState, useEffect, useRef} from "react";
import "./TextEditor.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import cookie from "react-cookies";
import axios from "axios";
import {toast} from "react-toastify";
import {Render} from "./Render";
import {useIntl} from "react-intl";
import translationsConfig from "../../../translations/translationsConfig";
import {legacy_set_EditorAttributeSave} from "../legacy_functions/legacyFunctions";
// import {
//     legacy_set_EditorAttributeEdit,
//     legacy_set_EditorAttributeInit,
//     legacy_set_EditorAttributeSave,
// } from "../legacy_functions/legacyFunctions";

export const Scenary = (props) => {
    const [contentBlock, setContentBlock] = useState(htmlToDraft(props.scenarioBody));
    const [contentState, setContentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

    const [files, setFiles] = useState([]);
    const [fileExist, setFileExist] = useState(false);
    const [isAllowRed, setIsAllowRed] = useState(false);
    const [isReadonly, setIsReadonly] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [reg, setReg] = useState(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

    // states from props (setStatesFromProps in useEffect)
    const [html, setHtml] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [confirmed, setConfirmed] = useState(null);
    const [isActive, setIsActive] = useState(null);
    const [fromAddress, setFromAddress] = useState(null);
    const [scenarioSubject, setScenarioSubject] = useState(null)

    // refs
    const scenaryFromAddressRef = useRef(null);
    const scenarySubject = useRef(null);

    const [menuOpened, setMenuOpened] = useState(false);
    const showMenu = () => setMenuOpened(true);

    const [focusedInput, setFocusedInput] = useState(null);
    const handleBlur = () => setFocusedInput(null); 
    const handleFocus = (arg) => setFocusedInput(arg);
    

    // translations
    const intl = useIntl()

    useEffect(() => {
        setStatesFromProps();
        // legacy_set_EditorAttributeInit();

        const savedLanguage = localStorage.getItem('CURRENTLANGUAGE');
        if (savedLanguage) {
            translationsConfig.CURRENTLANGUAGE = savedLanguage;
        }
    }, []);

    const setStatesFromProps = () => {
        setHtml(props.scenarioBody);
        setFileName(props.scenriofilename);
        setConfirmed(props.isActive);
        setFromAddress(props.fromadress);
        setScenarioSubject(props.scenarioSubject)
    }

    const showBPopup = () => {
        setShowPopup(!showPopup)
    }

    const onEditorStateChange = (editorState) => {
      setEditorState(editorState);
    };

    const updateCustomerRecords = async () => {
      const data = {}; 
      const url = `${props.host}updatecustomerrecords`;
      const headers = {
          "Content-Type": "application/json",
          xtoken: cookie.load("xtoken"),
      };
  
      try {
          const response = await axios.post(url, data, { headers });
  
          if (response.data.status === "success") {
              console.log("Customer records updated successfully.");
              // props.selectScenariosSubject();
          } else {
              console.error("Failed to update customer records:", response.data);
          }
      } catch (error) {
          console.error("Error updating customer records:", error);
      }
  };
  

    const saveHtml = () => {
      setIsAllowRed(false);
      setIsReadonly(true);
      setShowPopup(false);
      setIsActive(false);
      setMenuOpened(false);
      
      const from = scenaryFromAddressRef.current.value
    
      if (from && from.length !== 0 && reg.test(from)) {
        // legacy_set_EditorAttributeSave()
        let data = JSON.stringify({
          ScenarySubject: scenarySubject.current.value,
          EditorCurrentVAlue: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          ScenarioId: props.scenarioId,
          AddedFile: files[0] ? files[0].base64 : (fileExist ? props.scenarioaddedfile : null),
          NameOfAddedFile: files[0] ? files[0].name : (fileExist ? props.scenriofilename : null),
          scenarioType: fileExist ? props.scenariotype : props.scenarioType,
          FromAdress: scenaryFromAddressRef.current.value ? scenaryFromAddressRef.current.value  : null
        });
    
        if (!files[0] && !fileExist) {
          setFileExist(false); // This seems only relevant in the 'else' case
        } else {
          setFileName(files[0] ? files[0].name : props.scenriofilename);
        }
    
        axios.post(`${props.host}savehtml`, data, {
          headers: {
            "Content-Type": "application/json",
            xtoken: cookie.load("xtoken"),
          },
        })
        .then(response => {
          if (response.data.status === "success") {
            updateCustomerRecords();
            toast.success(intl.formatMessage({ id: 'scenary_scenario_saved' }));
          } else {
            toast.warning(intl.formatMessage({ id: 'scenary_scenario_notsaved' }));
          }
        })
        .catch(error => {
          toast.error(intl.formatMessage({ id: 'toast_error' }));
          console.log(error);
        });
      }
    };

    const allowRed = () => {
        setIsAllowRed(true);
        setIsReadonly(false);
        setIsActive(true);
        setMenuOpened(true);
        // legacy_set_EditorAttributeEdit()

        toast.info(intl.formatMessage({ id: 'scenary_document_editable' }));
    }

    return (
        <>
            <Render
                showMenu={showMenu}
                menuOpened={menuOpened}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                focusedInput={focusedInput}
                allowRed={allowRed}
                showBPopup={showBPopup}
                saveHtml={saveHtml}
                onEditorStateChange={onEditorStateChange}
                scenariotype={props.scenariotype}
                scenarySubject={scenarySubject}
                scenarioSubject={scenarioSubject}
                scenaryFromAddressRef={scenaryFromAddressRef}
                showPopUp={showPopup}
                setShowPopup={setShowPopup}
                fromAddress={fromAddress}
                isReadonly={isReadonly}
                isActive={isActive}
                isAllowRed={isAllowRed}
                editorState={editorState}
                toggleIsClicked={props.toggleIsClicked}
           />
        </>
    )
}
