import React from 'react'
import {NavLink, Route, Routes} from "react-router-dom";
import {Logout} from "../functions/functions";
import {List} from "./list/List";
import {Profile} from "./profile/Profile";
import {Reports} from "./reports/Reports";
import {Scenaries} from "./scenaries/Scenaries";
import {StartPhishing} from "./startPhishing/startPhishing";
import {Loader} from "./Loader";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

export const Render = (props) => {
    return (
      <>
        {props.updated ? (
        <>
          <div className="Mycabinet layout">
            <div className={classNames("overlay", {open: props.menuOpened, showCabinetMenu: props.menuOpened})}></div>
              <div className="navbar">
                  <div className="hamburger-menu">
                      <button
                          className={classNames("hamburger-menu-btn hamburger-menu-btn_open", {
                              open: !props.menuOpened,
                              showCabinetMenu: props.menuOpened
                          })}
                          onClick={props.showMenu}>
                          <i className="fas fa-bars"></i>
                      </button>
                      <button
                          className={classNames("hamburger-menu-btn hamburger-menu-btn_close", {
                              open: props.menuOpened,
                              showCabinetMenu: props.menuOpened
                          })}
                          onClick={props.hideMenu}>
                          <i className="fas fa-times"></i>
                      </button>
                      <ul className={classNames("menu__box menu__box_cabinet", {open: props.menuOpened})}>
                          <li className="menu-cabinet__item" onClick={props.showProfile}
                              ref={props.menuCabinetItems.current[0]}>
                              <NavLink to="/cabinet/profile"><FormattedMessage id="cabinet_link_profile"/></NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[1]}>
                              <NavLink to="/cabinet/list" onClick={props.hideMenu}>
                                  {" "}
                                  <FormattedMessage id="cabinet_link_staff"/>
                              </NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[2]}>
                              <NavLink to="/cabinet/scenaries" onClick={props.showScenaries}>
                                  <FormattedMessage id="cabinet_link_scenarios"/>
                              </NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[3]}>
                              <NavLink to="/cabinet/startphishing" onClick={props.showStartPhishing}>
                                  <FormattedMessage id="cabinet_link_launch"/>
                              </NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[4]}>
                              <NavLink to="/cabinet/reports" onClick={props.hideMenu}><FormattedMessage
                                  id="cabinet_link_reports"/></NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[5]}>
                              <NavLink target="_blank" to="/my_fpassword-change" onClick={props.hideMenu}>
                                  <FormattedMessage id="cabinet_link_passpayload"/>
                              </NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[6]}>
                              <NavLink target="_blank" to="/my_flogin-page" onClick={props.hideMenu}>
                                  <FormattedMessage id="cabinet_link_loginpayload"/>
                              </NavLink>
                          </li>
                          <li className="exit_btn menu-cabinet__item" onClick={Logout}
                              ref={props.menuCabinetItems.current[7]}>
                              <a href="#"><i className="fas fa-sign-out-alt"></i>Logout</a>
                          </li>
                      </ul>
                  </div>

                  <div
                      className={classNames("cabinet-navbar-menu", {showCabinetMenu: props.menuOpened})}>
                      <ul>
                          <li className="menu-cabinet__item" onClick={props.showProfile}
                              ref={props.menuCabinetItems.current[8]}>
                              <NavLink to="profile"><FormattedMessage id="cabinet_link_profile"/></NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[9]}>
                              <NavLink to="/cabinet/list"> <FormattedMessage id="cabinet_link_staff"/></NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[10]}>
                              <NavLink to="/cabinet/scenaries" onClick={props.showScenaries}>
                                  <FormattedMessage id="cabinet_link_scenarios"/>
                              </NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[11]}>
                              <NavLink to="/cabinet/startphishing" onClick={props.showStartPhishing}>
                                  <FormattedMessage id="cabinet_link_launch"/>
                              </NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[12]}>
                              <NavLink to="/cabinet/reports"><FormattedMessage id="cabinet_link_reports"/></NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[13]}>
                              <NavLink target="_blank" to="/my_fpassword-change" onClick={props.hideMenu}>
                                  <FormattedMessage id="cabinet_link_passpayload"/>
                              </NavLink>
                          </li>
                          <li className="menu-cabinet__item" ref={props.menuCabinetItems.current[14]}>
                              <NavLink target="_blank" to="/my_flogin-page" onClick={props.hideMenu}>
                                  <FormattedMessage id="cabinet_link_loginpayload"/>
                              </NavLink>
                          </li>
                          <li className="exit_btn menu-cabinet__item" onClick={Logout}
                              ref={props.menuCabinetItems.current[15]}>
                              <a href="#"><i className="fas fa-sign-out-alt"></i>Logout</a>
                          </li>
                      </ul>
                  </div>
              </div>

              <button onClick={props.topFunction} className={classNames({
                  displayBlock: props.menuOpened,
                  displayNone: props.menuOpened
              })}
                      id="myBtn" title="Go to top">
                  <i className="fas fa-arrow-up"></i>
              </button>

              <div className="mycabinet-container">
                  <Routes>
                      <Route
                          path="list"
                          element={<List isScenarioActive={props.isScenarioActive} pagesCount={props.pagesCount}
                                         host={props.host}/>}
                      />
                      <Route
                          path="profile"
                          element={
                              <Profile
                                  isScenarioActive={props.isScenarioActive}
                                  statisticinfo={props.statisticsInfo}
                                  clientInfo={props.clientInfo}
                                  statisicPhishing={props.statisticsPhishing}
                                  scenariosSubjects={props.scenariosSubjects}
                                  host={props.host}
                              />
                          }
                      />
                      <Route
                          path="reports"
                          element={<Reports host={props.host}/>}
                      />
                      <Route
                          path="scenaries"
                          element={<Scenaries selectedScenarios={props.selectedScenarios}
                                              arrayScenarioIdAndSubject={props.arrayScenarioIdAndSubject}
                                              host={props.host} isScenarioActive={props.isScenarioActive}/>}
                      />
                      <Route
                          path="startphishing"
                          element={<StartPhishing arrayScenarioIdAndSubject={props.arrayScenarioIdAndSubject}
                                                  host={props.host}/>}
                      />
                  </Routes>
              </div>
          </div>
        </>
        ) : <Loader/>}
      </>
    )
}

