import React from "react";
import boyWithFish from "../../../assets/images/mainpage/firstscreen/boyWithFish.png";
import { NavLink } from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {motion} from "framer-motion";

const Firstscreen = () => {

  return (
    <div className="Firstscreen layout container__fixed">
      <div className="firstscreen__left">
        <div className="firstscreen__container ">
          <div className="firstscreen__header">
            <motion.h1 initial={{x: -200, opacity: 0}} transition={{duration: 0.4}} whileInView={{x: 0, opacity: 1}}>GO PHISH!</motion.h1>
            <motion.h2 initial={{x: -200, opacity: 0}} transition={{duration: 0.4, delay: 0.3}} whileInView={{x: 0, opacity: 1}}>
              <FormattedMessage id="mp_firstscreen_harden"/> <br /> <FormattedMessage id="mp_firstscreen_against"/>
            </motion.h2>
          </div>
          <motion.div className="firstscreen__btn" initial={{y: 200, opacity: 0}} transition={{duration: 0.4, delay: 0.6}} whileInView={{y: 0, opacity: 1}}>
            <NavLink to="/login" className="btn_orange">
              {" "}
              <FormattedMessage id="mp_login"/>{" "}
            </NavLink>
          </motion.div>
        </div>
      </div>

      <motion.div className="firstscreen__right" initial={{x: 200, opacity: 0}} transition={{duration: 0.8, delay: 0.2}} whileInView={{x: 0, opacity: 1}}>
        <img src={boyWithFish} className="boyWithFish" alt="about" />
      </motion.div>
    </div>
  );
};

export default Firstscreen;