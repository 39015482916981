import React, {useState, useEffect} from "react";
import axios, {get} from "axios";
import "./fakepage.css";
import logoExample from "../../../../assets/images/cabinet/scenary/fakepage/logo-example.png";
import cookie from "react-cookies";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import {FakePasswordRender} from "./FakePasswordRender";

export const FakePasswordChangePage = (props) => {
    const [filesLogo, setFilesLogo] = useState([]);
    const [fileLogoExist, setFileLogoExist] = useState(false);
    const [fileLogoName, setFileLogoName] = useState("");
    const [filesBg, setFilesBg] = useState([]);
    const [fileBgExist, setFileBgExist] = useState(false);
    const [fileBgName, setFileBgName] = useState("");
    const [message, setMessage] = useState("");
    const [logo, setLogo] = useState(logoExample);
    const [background, setBackground] = useState(null);
    const intl = useIntl()
    const [activeWindow, setActiveWindow] = useState(false);
    const hideWindow = () => setActiveWindow(false);
    const showWindow = () => setActiveWindow(true)

    useEffect(() => {
        axios
          .get(`${props.host}getlogoandbg`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                if (response.data.data[0].logo && response.data.data[0].background) {
                    setLogo(response.data.data[0].logo);
                    setBackground(`url(${response.data.data[0].background})  `)
                } else {
                  backToDefaults()
                }
              } else if (response.data.status === "failed") {
                console.log("something wrong");
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }, []);

    const backToDefaults = () => {
        setLogo(logoExample);
        setBackground(null);
    }

    const getLogo = (filesLogo) => {
        setFilesLogo(filesLogo);
        setFileLogoExist(true);
        setFileLogoName(filesLogo[0].name)
    }

    const getBg = (filesBg) => {
        setFilesBg(filesBg);
        setFileBgExist(true)
        setFileBgName(filesBg[0].name)
    }

    const updateImages = () => {
        axios
          .get(`${props.host}getlogoandbg`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                  toast.success(intl.formatMessage({ id: 'fake_password_images_updated' }))
                if (response.data.data[0].logo && response.data.data[0].background) {
                    setLogo(response.data.data[0].logo);
                    setBackground(`url(${response.data.data[0].background})  `)
                } else {
                    backToDefaults()
                }
              } else if (response.data.status === "failed") {
                  toast.warning(intl.formatMessage({ id: 'fake_password_images_notupdated' }));
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    const deleteImages = () => {
        axios
          .get(`${props.host}deletelogoandbg`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                  toast.success(intl.formatMessage({ id: 'fake_password_images_deleted' }))
                  setMessage("Files deleted")
                  backToDefaults();
              } else if (response.data.status === "failed") {
                  toast.warning(intl.formatMessage({ id: 'fake_password_images_notdeleted' }))
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    const saveImages = () => {
        if (fileLogoExist === true && fileBgExist === true) {
          let logo = filesLogo[0].base64;
          let logoName = filesLogo[0].name;
          let background = filesBg[0].base64;
          let backgroundName = filesBg[0].name;

          const data = JSON.stringify({
            logo: logo,
            logoName: logoName,
            background: background,
            backgroundName: backgroundName,
          });

          axios
            .post(`${props.host}savelogoandbg`, data, {
              headers: {
                "Content-Type": "application/json",
                xtoken: cookie.load("xtoken"),
              },
            })
            .then(
              function (response) {
                if (response.data.status === "success") {
                    toast.success(intl.formatMessage({ id: 'fake_password_images_saved' }));
                    updateImages();
                } else if (response.data.status === "failed") {
                    toast.warning(intl.formatMessage({ id: 'fake_password_images_notsaved' }));
                }
              }
            )
            .catch(function (error) {
              console.log(error);
            });
        } else {
          setMessage("Upload files")
        }
    }

    return (
        <>
            <FakePasswordRender
                getLogo={getLogo}
                getBg={getBg}
                saveImages={saveImages}
                deleteImages={deleteImages}
                activeWindow={activeWindow}
                showWindow={showWindow}
                hideWindow={hideWindow}

                background={background}
                message={message}
                logo={logo}
            />
        </>
    )
}