import React from "react";
import { NavLink } from "react-router-dom";
import {FormattedMessage} from "react-intl";

const Footer = () => {
  return (
    <div className="footer ">
      <div className="footer_contaigner layout container__fixed">
        <div className="footer_menu">
          <ul>
            <li>
              <NavLink to="/"><FormattedMessage id="mp_main_page"/></NavLink>
            </li>
            <li>
              <a href="#phishing__scroll"><FormattedMessage id="mp_about_phishing"/></a>
            </li>
            <li>
              <a href="#aboutSite__scroll"><FormattedMessage id="mp_about_website"/></a>
            </li>
            <NavLink to="/loginen">
              {" "}
              <span>
                <i className="fas fa-sign-in-alt"></i><FormattedMessage id="mp_login"/>
              </span>
            </NavLink>
          </ul>
        </div>
        <div className="footer_logo"></div>
      </div>
    </div>
  );
};

export default Footer;
