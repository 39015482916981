import React from "react";

export const Loader = () => {
    return (
              <>
                  <div className="Profile profile-container">
                      <div className="loader_contaigner">
                          <div className="loader"></div>
                      </div>
                  </div>
              </>
          );
}
