// import './App.css';
import "./css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";
import React from 'react';
import {IntlProvider} from 'react-intl';
import translationsConfig, {CURRENTLANGUAGE} from './translations/translationsConfig.js'
import {messages} from "./translations/languages/messages";
import {LOCALES} from "./translations/locales";

import Mainpage from "./components/mainpage/Mainpage";
import {Login} from "./components/login/Login.js";
import Cabinet from './components/Cabinet/Cabinet';
import { WhatIsPhishing } from "./components/WhatIsPhishing/WhatIsPhishing.js";

import {FakePasswordChangePage} from "./components/Cabinet/scenary/fakepage/FakePasswordChange";
import {FakeLoginPage} from "./components/Cabinet/scenary/fakepage/FakeLoginPage";
//
// // For customers only! -- this is what is shown to customers -
import {FakePageForCustomerPasswordChange} from "./components/Cabinet/scenary/fakepage/fakepageformail/FakePageForCustomerPasswordChange";
import {FakePageForCustomerLogin} from "./components/Cabinet/scenary/fakepage/fakepageformail/FakePageForCustomerLogin";

function App() {
    const [host, setHost] = useState(process.env.REACT_APP_API_PATH);
    const [currentMessages, setCurrentMessages] = useState('')

     useEffect(() => {
        const languageKey = Object.keys(LOCALES).find(key => LOCALES[key] === translationsConfig.CURRENTLANGUAGE);
        setCurrentMessages(messages[languageKey])
    }, [translationsConfig.CURRENTLANGUAGE]);

    const changeLanguage = (e) => {
        const newLanguage = translationsConfig.CURRENTLANGUAGE === 'en' ? 'ru' : 'en';
        translationsConfig.CURRENTLANGUAGE = newLanguage;
        setCurrentMessages(messages[newLanguage]);

        e.target.innerText = translationsConfig.CURRENTLANGUAGE

        localStorage.setItem('CURRENTLANGUAGE', newLanguage);
    }

  return (
    <div className="App">
        <IntlProvider locale={translationsConfig.CURRENTLANGUAGE} defaultLocale={translationsConfig.CURRENTLANGUAGE} messages={currentMessages}>
            <Routes>
              <Route path="/cabinet/*" element={<Cabinet host={host} changeLanguage={changeLanguage}/>}/>
              <Route path="/" element={<Mainpage changeLanguage={changeLanguage}/>} />
              <Route path="/login" element={<Login host={host} currentLanguage={CURRENTLANGUAGE}/>}/>
              <Route path="/my_fpassword-change"  element={<FakePasswordChangePage host={host}/>}/>
              <Route path="/my_flogin-page" element={<FakeLoginPage host={host}/>}/>
              <Route
                path="/companyportal-login"
                element={<FakePageForCustomerLogin host={host} />}/>
              <Route
                path="/companyportal-passchange"
                element={<FakePageForCustomerPasswordChange host={host} />}/>
                <Route path="/whatisphishing" element={<WhatIsPhishing host={host} changeLanguage={changeLanguage}/>} />
            </Routes>
        </IntlProvider>
    </div>
  );
}

export default App;
