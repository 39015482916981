import React, {useEffect, useRef, useState} from "react";
import {Render} from "./Render";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import './css/login.css'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import cookie from "react-cookies";
import {useIntl} from "react-intl";

export const Login = (props) => {
    const loginForm = useForm({mode: 'onChange'});
    const registerForm = useForm({mode: 'onChange'});

    const [switchToReg, setSwitchToReg] = useState(false);
    const toggleSwitchToReg = () => setSwitchToReg(!switchToReg);

    const navigate = useNavigate();
    const intl = useIntl();

    const [inputRegex, setInputRegex] = useState({
        email: new RegExp("^[a-zA-Z]+[\\w\\d]*@[a-zA-Z\\d]+\\.[a-zA-Z]+$"),
        password: new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,16}$"),
        string: new RegExp("^[A-Za-zа-яА-Я\\s\\p{P}]{3,}$", "u"),
    })

    useEffect(() => {
        let getparams = window.location.search;
        let myParam = getparams.split("?conf=");

        if (myParam[1] === "success") {
            toast.success(intl.formatMessage({id: 'login_toast_verified'}))
        } else if (myParam[1] && myParam[1] !== "success"){
            toast.warning(intl.formatMessage({id: 'toast_error'}));
        }
    }, [])

    const submitError = (errors) => {
        let displayedMessages = [];

        Object.values(errors).forEach(error => {
            if (error.message && !displayedMessages.includes(error.message)) {
                toast.warning(error.message);
                displayedMessages.push(error.message);
            }
        });
    };

    const isEmail = (value) => {
        return inputRegex.email.test(value) || intl.formatMessage({id: 'toast_email_format_wrong'})
    };

    const isPassword = (value) => {
        return inputRegex.password.test(value) || intl.formatMessage({id: 'toast_password_format_wrong'});
      };

    const isText = (value) => {
        return inputRegex.string.test(value) || intl.formatMessage({id: 'toast_text_format_wrong'});
    };

    const submitLogin = async data => {
        const loginData = {
            Email: data.email,
            Password: data.password,
        }

        try{
            const response = await axios.post(
                `${props.host}login`,
                loginData,
                {headers: {
                "Content-Type": "application/json",
              },
            })

            if(response.data.status === 'success'){
                navigate('/cabinet/profile/');
                cookie.save("xtoken", response.data["xtoken"], {
                    maxAge: 24 * 60 * 60 * 1000,
                });
                loginForm.reset();
            } else if (response.data.accountactive === false) {
                toast.warning(intl.formatMessage({id: 'login_toast_not_verified'}))
            } else if (response.data.status === "failed") {
                toast.warning(intl.formatMessage({id: 'login_toast_invalid_data'}))
            }

        } catch(error){
            toast.error(intl.formatMessage({id: 'toast_error'}))
            console.log('Error: ', error)
        }
    }

    const submitRegister = async data => {
        const registerData = {
            Email: data.email,
            Password: data.password,
            registrationKey: data.key,
            lang: "en",
        }

        try{
            const response = await axios.post(
                `${props.host}registration`,
                registerData,
                {headers: {
                "Content-Type": "application/json",
              },
            })

            if(response.data.status === 'success'){
                toast.success(intl.formatMessage({id: 'login_toast_confirmation_letter'}));
                toggleSwitchToReg();
                registerForm.reset();
            } else if (response.data.status === "failed") {
                toast.warning(intl.formatMessage({id: 'login_toast_user_already_exists'}))
            }


        } catch(error){
            toast.error(intl.formatMessage({id: 'toast_error'}));
            console.log('Error:', error)
        }
    }



    return (
        <Render
            // actual forms
            loginForm={loginForm}
            registerForm={registerForm}

            // form slider to switch between forms
            switchToReg={switchToReg}
            toggleSwitchToReg={toggleSwitchToReg}

            // form submit functions
            submitLogin={submitLogin}
            submitRegister={submitRegister}
            submitError={submitError}

            // validation
            isEmail={isEmail}
            isPassword={isPassword}
            isText={isText}
        />
    )
}