import * as Papa from 'papaparse'
import React, {useEffect, useRef, useState} from "react";
import "./List.css";
import axios from "axios";
import cookie from "react-cookies";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useIntl} from "react-intl";
import {Render} from "./Render";

export const List = (props) => {
    const [isScenarioActive, setIsScenarioActive] = useState(false);
    const [csvData, setCsvData] = useState("");
    const [sampleData, setSampleData] = useState("")
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [arrayStaff, setArrayStaff] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [smallMobile, setSmallMobile] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showMessage, setShowMessage] = useState("");


    const [staffButtonStatus, SetStaffButtonStatus] = useState(true)
    const [firstnameButtonStatus, SetFirstnameButtonStatus] = useState({disabled: true, readOnly: true})
    const [lastnameButtonStatus, SetLastnameButtonStatus] = useState({disabled: true, readOnly: true})
    const [emailButtonStatus, SetEmailButtonStatus] = useState({disabled: true, readOnly: true})
    const [departmentButtonStatus, SetdepartmentButtonStatus] = useState({disabled: true, readOnly: true})
    const [editableRowId, setEditableRowId] = useState(null);
    const [trashButtonStatus, settrashButtonStatus] = useState(null);
    const [penButtonStatus, setpenButtonStatus] = useState(null);
    const [saveButtonStatus, setsaveButtonStatus] = useState('disabled-btn');

    // for downloading example
    const [file, setFile] = useState(null);

    // for loader displaying
    const [staffListLoaded, setStaffListLoaded] = useState(false);
    
    const intl = useIntl();

     useEffect(() => {
      selectStaff(activePage);
      fetchStaffCount();
      fetchScenarioStatus();
      createFile();
    }, []);

    useEffect(() =>{
      modifyRefs(arrayStaff);
    },[arrayStaff])



    const fetchStaffCount = async () => {
      try {
        const response = await axios.get(`${props.host}countstaff`, {
          headers: {
            "Content-Type": "application/json",
            xtoken: cookie.load("xtoken"),
          },
        });
    
        if (response.data.status === "success") {
          const count = Number(response.data.result);
          if (count > 0) {
            setPagesCount(count);
          } else{
            console.log("No staff count available", response.data.message);
            setPagesCount(0);
          }
        } else if(response.data.status === "failed"){
            console.log("Something goes wrong while trying to fetch staff count", response.data.message)
        }
      } catch (error) {
        console.error("Failed to fetch staff count:", error);
      }
    };

  
    const fetchScenarioStatus = async () => {
         axios
          .get(`${props.host}isscenarioactive`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
                if(response.data.status === 'success'){
                    const result = response.data.result
                    let active = false;

                    result.forEach((element) => {
                        if(element.active) active = true
                    })
                    setIsScenarioActive(active);
                }
            }
          )
          .catch(function (error) {
            console.log(error);
            setIsScenarioActive(false);
          });
      }
  

    const deleteAllStaff = async () => {
      try {
        const { data } = await axios.post(`${props.host}deleteallstaff`, { message: "massage" }, {
        headers: {
          "Content-Type": "application/json",
          xtoken: cookie.load("xtoken"),
        }
      });
        if (data.status === 'success') {
          toast.success(intl.formatMessage({ id: 'list_message_deleted_success' }));
          setIsFileUploaded(false);
          setCsvData(null);
          setArrayStaff([]);
          await selectStaff(0);
        } else {
          console.error("Deletion failed: ", data.message);
          toast.warning(intl.formatMessage({ id: 'list_message_deleted_not_deleted' }));
        }

      } catch (error) {
        console.error("Deletion error: ", error);
        toast.error(intl.formatMessage({ id: 'list_message_delete_error' }));
      }
    };
    
    const selectStaff = async (activePage) => {
      try {
        const response = await axios.get(`${props.host}selectstaff?currentpage=${activePage}`, {
          headers: {
            "Content-Type": "application/json",
            xtoken: cookie.load("xtoken"),
          },
        });
    
        if (response.data.status === "success") {
          if (response.data.result === 0) {
            setArrayStaff([]);
          } else {
            setArrayStaff(response.data.result);
          }
        } else if(response.data.status === "failed"){
            console.log("Error occurred:", response.data.message);
            setArrayStaff([]);
        }
        setStaffListLoaded(true);

      } catch (error) {
        console.error("Failed to fetch staff records:", error);
      }
    };
    
    

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    selectStaff(pageNumber);
  }

  const handleForce = (data) => {
    let csv = Papa.unparse(data);
    setSampleData(csv);
    setCsvData(csv);
    if (!data[0]) {
       toast(intl.formatMessage({ id: 'list_message_empty_document' }))
       setErrorMessage(intl.formatMessage({ id: 'list_message_empty_document' }))
    }
  }

  const cancelUpload = () => {
     setCsvData(null);
     setErrorMessage(null)
  }

  const updateCustomerRecords = () => {
    let data = {};
    axios
      .post(`${props.host}updatecustomerrecords`, data, {
        headers: {
          "Content-Type": "application/json",
          xtoken: cookie.load("xtoken"),
        },
      })
      .then(
        function (response) {
            if (response.data.status === "success") {
                toast.success(intl.formatMessage({ id: 'list_message_updated_success' }))
            } else if (response.data.status === "failed") {
                console.log("Something wrong while updating customer records:", response.data.message);
                toast.warning(intl.formatMessage({ id: 'list_message_updated_unsuccess' }))
            }
        }
      )
      .catch(function (error) {
        console.log(error);
      });
  }

  const uploadStaff = () => {
    const { data } = Papa.parse(sampleData, { header: true });

    if (!data || data.length === 0) {
        const message = intl.formatMessage({ id: 'list_message_invalid_csv' });
        setErrorMessage(message);
        toast.error(message);
    }
    const expectedHeaders = ["first_name", "last_name", "email", "department"];
    const tableHeaders = Object.keys(data[0]);

    const isValidHeaders = expectedHeaders.every((header, index) => header === tableHeaders[index]);

    if (!isValidHeaders) {
        const message = intl.formatMessage({ id: 'list_message_incorrect_csv' });
        setErrorMessage(message);
        toast.error(message);
    }

    axios.post(`${props.host}uploadstaff`, data, {
        headers: {
            "Content-Type": "application/json",
            xtoken: cookie.load("xtoken"),
        },
    })
    .then(response => {
        if (response.data.status === "success") {
            setIsFileUploaded(true);
            updateCustomerRecords();
            toast.success(intl.formatMessage({ id: 'list_message_upload_success' }));
        } else if (response.data.status === "failed") {
            toast.error(intl.formatMessage({ id: 'list_message_upload_failed' }));
            console.log(`Something wrong while uploading staff: ${response.data.message}`);
        }
    })
    .catch(error => {
        console.error(error);
        toast.error(error.message || intl.formatMessage({ id: 'list_message_upload_error' }));
    });
};


const showStaffList = async () => {
  try {
    const response = await axios.post(`${props.host}showstafflist`, { message: "message" }, {
      headers: {
        "Content-Type": "application/json", 
        xtoken: cookie.load("xtoken"),
      },
    });

    if (response.data.status === "success") {
      setArrayStaff(response.data.result);
      setShowPopup(false);
      setActivePage(0);
      await selectStaff(activePage);
    } else if(response.data.status === "failed"){
      console.error("Failed to fetch staff list: something wrong", response.data.message);
    }

  } catch (error) {
    console.error("Error fetching staff list:", error);
  }
};

  const deleteOneStaff = (staffId) => {
 
    const data = JSON.stringify({
      staffid: staffId,
    });
  
    axios.post(`${props.host}deleteonestaff`, data, {
      headers: {
        "Content-Type": "Application/json",
        xtoken: cookie.load("xtoken"),
      },
    })
    .then(response => {
      if (response.data.status === "success") {
        setIsFileUploaded(false);
        const updatedStaff = arrayStaff.filter(staff => staff.staffid !== staffId);
        setArrayStaff(updatedStaff);
        toast.success(intl.formatMessage({ id: 'list_message_member_deleted' }));
      } else if(response.data.status === "failed") {
        toast.error(intl.formatMessage({ id: 'list_message_member_not_deleted' }));
        console.log('Something goes wrong: deletion of one staff member failed', response.data.message)
      }
    })
    .catch(error => {
      console.error(error);
      toast.error(intl.formatMessage({ id: 'error_message_generic' }));
    });
  };

  const saveOneStaff = (e) => {
      let data = JSON.stringify({
        staffid: e.staffid,
        firstname: e.firstname,
        lastname: e.lastname,
        email: e.email,
        department: e.department,
      });

      axios
        .post(`${props.host}saveonestaff`, data, {
          headers: {
            "Content-Type": "Application/json",
            xtoken: cookie.load("xtoken"),
          },
        })
        .then(
          function (response) {
            if (response.data.status === "success") {
              toast.success(intl.formatMessage({ id: 'list_message_updated_success' }));
            } else if(response.data.status === "failed") {
              toast.warning(intl.formatMessage({ id: 'list_message_updated_unsuccess' }));
              console.log('Something goes wrong while saving one staff member', response.data.message)
            }
          }
        )
        .catch(function (error) {
          console.log(error);
        });
    
  }

  const createFile = () => {
      const csvContent = "data:text/csv;charset=utf-8,first_name;last_name;email;department\nJohn;Doe;johndoe@gmail.com;sales\nJane;Doe;janedoe@gmail.com;IT\nJack;Jackson;jack.jackson@protonmail.com;security";
      const encodedUri = encodeURI(csvContent);

      setFile(encodedUri)
  }


  const toggleRowEditability = (staffId) => {
    setEditableRowId(prevId => prevId === staffId ? null : staffId);
    setpenButtonStatus(prevId => prevId === staffId ? "disabled-btn" : "enabled-btn")
    setsaveButtonStatus(prevId => prevId === staffId ? "enabled-btn" : "disabled-btn")
  };

  const inputRefs = useRef({});
  const downloadRef = useRef();

  const modifyRefs = (staffMembers) => {
    if (Array.isArray(staffMembers)) {
      staffMembers.forEach((staffMember) => {
        inputRefs.current[staffMember.staffid] = {
          firstname: React.createRef(),
          lastname: React.createRef(),
          email: React.createRef(),
          department: React.createRef(),
        };
      });
    }
  };


  const handleSave = (staffId) => {
    const refs = inputRefs.current[staffId];
    const updatedData = {
      staffid: staffId,
      firstname: refs.firstname.current.value,
      lastname: refs.lastname.current.value,
      email: refs.email.current.value,
      department: refs.department.current.value,
    };

    toggleRowEditability(staffId)
    if (!isScenarioActive) {
      saveOneStaff(updatedData);
    }
  };

  const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    };

  return (
      <>
        <Render
            handleForce={handleForce}
            uploadStaff={uploadStaff}
            cancelUpload={cancelUpload}
            showStaffList={showStaffList}
            handlePageChange={handlePageChange}
            deleteOneStaff={deleteOneStaff}
            deleteAllStaff={deleteAllStaff}
            handleSave={handleSave}

            setArrayStaff={setArrayStaff}
            arrayStaff={arrayStaff}
            isFileUploaded={isFileUploaded}
            papaparseOptions={papaparseOptions}
            errorMessage={errorMessage}
            csvData={csvData}
            activePage={activePage}
            pagesCount={pagesCount}
            isScenarioActive={isScenarioActive}
            smallMobile={smallMobile}
            showPopup={showPopup}
            showMessage={showMessage}
            setShowPopup={setShowPopup}
            setShowMessage={setShowMessage}

            staffButtonStatus={staffButtonStatus}
            trashButtonStatus={trashButtonStatus}
            saveButtonStatus={saveButtonStatus}

            firstnameButtonStatus={firstnameButtonStatus}
            lastnameButtonStatus={lastnameButtonStatus}
            emailButtonStatus={emailButtonStatus}
            departmentButtonStatus={departmentButtonStatus}
            editableRowId={editableRowId}
            penButtonStatus={penButtonStatus}
            setEditableRowId={setEditableRowId}
            setpenButtonStatus={setpenButtonStatus}

            file={file}
            inputRefs={inputRefs}
            downloadRef={downloadRef}

            staffListLoaded={staffListLoaded}
        />
      </>
  )
}