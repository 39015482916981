export const ru = {
    phished_you: "Вы",
    phished_were: "пойманы",
    phished_phished: "на фишинг!",
    phished_you_here: "Вы здесь, потому что перешли по ссылке в электронном письме или загрузили прикрепленный файл. На самом деле адрес поддельный, а отправителем на самом деле является хакер, пытающийся заполучить ваши данные.",
    phished_good_news: "Хорошие новости? Такие атаки могут послужить тревожным сигналом. Никакие данные не были переданы и вредоносное ПО не было установлено. Будьте бдительны в отношении всех писем, которые попадают в ваш почтовый ящик. Даже нажатие на ссылку в электронном письме может привести к установке вирусов. Сообщайте обо всем подозрительном в отдел ИТ (безопасности).",
    phished_howto: "Как распознать фишинг?",
    phished_ask_yourself: "Спросите себя:",
    phished_howdoes: "Откуда отправитель знает ваш адрес и почему вы видите электронное письмо?",
    phished_doesthe: "Имеет ли содержание смысл и подходит ли язык отправителю?",
    phished_is_sender: "Отправитель отправляет электронное письмо под правильным именем?",
    phished_does_email: "Содержит ли электронное письмо какие-либо подозрительные ссылки или вложения?",
    phished_are_being: "Вас заставляют что-то сделать или вам настойчиво предлагают что-то?",
    phished_be_careful: "Будьте осторожны!",

    // main page
    mp_main_page: 'Главная',
    mp_about_phishing: 'Про фишинг',
    mp_about_website: "О сайте",
    mp_login: "Войти",

    mp_firstscreen_harden: "Анализ компании на устойчивость",
    mp_firstscreen_against: "к фишинговым атакам",

    mp_phishing_whatis: "Что такое фишинг ?",
    mp_phishing_text: "это вид интернет-мошенничества, целью которого является получение  доступа к конфиденциальным данным пользователей — логинам и паролям. Это достигается путём проведения массовых рассылок электронных писем  от имени популярных брендов, а также личных сообщений внутри различных сервисов, например, от имени банков или внутри социальных сетей. В письме часто содержится прямая ссылка на сайт, внешне неотличимый от настоящего, либо на сайт с редиректом. После того как пользователь попадает на поддельную страницу, мошенники пытаются различными психологическими приёмами побудить пользователя ввести на поддельной странице свои логин и пароль, которые он использует для доступа к определённому сайту, что позволяет мошенникам получить доступ к аккаунтам и банковским счетам.",

    mp_humanrisks_notonly: "Фишинговые атаки нацелены не только на руководителей и топ-менеджеров компании, но и на другие группы сотрудников.\n",
    mp_humanrisks_often: "Чаще всего злоумышленники используют для атаки самое слабое звено в цепочке системы защиты организации — рядового сотрудника.\n",
    mp_humanrisks_fora: "Для успешной атаки вполне достаточно в электронной переписке выдать себя за доверенное для пользователя лицо (коллегу, сотрудника службы безопасности или технической поддержки, сотрудника банка, государственного служащего)\n",

    mp_damages_why: "В чем его опасность?",
    mp_damages_ofsuccessful: "успешных кибератак использовали фишинг в качестве начального вектора проникновения",
    mp_damages_ofphished: "пользователей, попавшихся на фишинг, попадаются на фишинг еще раз в течение того же года",
    mp_damages_emails: "электронных писем содержит вредоносное програмное обеспечение",
    mp_damages_of: "из",

    mp_about_howcan: "Как наш сайт поможет уберечь вашу компанию от фишинговых атак?",
    mp_about_text: "Наш сайт предоставляет возможность провести проверку своих сотрудников на устойчивость к фишинговым атакам. Зарегистрированым пользователям в личном кабинете предоставляется доступ к различным сценариям фишинговой проверки. Все что Вам нужно - это выбрать подходящий сценарий и загрузить список сотрудников Вашей огранизации, которым предстоит пройти проверку. По истечению проверки вы получите развернутую аналитику. Это даст Вам возможность обнаружить слабые места организации и проводить дальнейшие целевое обучение и подготовку сотрудников к правильому реагированию на кибер атаки.",

    mp_steps_how: "Как это работает?",
    mp_steps_registration: "Регистрация",
    mp_steps_load: "Загружаете список\nсубъектов на проверку",
    mp_steps_scenario: "Выбираем сценарий",
    mp_steps_launch : "Запускаем симуляцию",
    mp_steps_getting: "Получаем статистику",

    cabinet_link_profile: 'Профиль',
    cabinet_link_staff: 'Участники',
    cabinet_link_scenarios: 'Сценарии',
    cabinet_link_launch: 'Запуск программы',
    cabinet_link_reports: 'Отчеты',
    cabinet_link_passpayload: 'Изменить пароль',
    cabinet_link_loginpayload: 'Изменить логин',

    login_email: 'Эл. почта',
    login_password: 'Пароль',
    login_login: 'Войти',
    login_registration: 'Регистрация',
    login_regkey: 'Регистрационный ключ',
    login_back: 'Назад ко входу',

    toast_email_format_wrong: "Неверный формат эл. почты",
    toast_password_format_wrong: "Неверный формат пароля. Должен быть не менее 8 символов в длину, содержать не меньше 1 большой буквы и 1 цифры",
    toast_text_format_wrong: "Неверный формат текстовых данных",

    login_toast_verified: "Ваш аккаунт был успешно подтвержден! Вы можете пользоваться сайтом",

    login_toast_not_verified: "Ваш аккаунт не верифицирован. Пожалуйста проверьте электронную почту",
    login_toast_invalid_data: "Неверный логин или пароль",
    login_toast_confirmation_letter: "Письмо для подтверждения регистрации отправлено на Вашу электронную почту.",
    login_toast_user_already_exists: "Вы ввели неверные данные либо пользователь с такой электронной почтой уже существует",

    toast_fields_required: "Пожалуйста заполните обязательные поля",

    profile_marquee_launched: 'Фишинговая кампания в процессе',
    profile_marquee_finished: 'Фишинговая кампания закончилась: ',
    profile_quantity_phished: 'Количество сотрудников заваливших тест',
    profile_settings: 'Настройки профиля',
    profile_details: 'Подробнее',
    profile_send: 'Отправить',
    profile_change_email: 'Изменить почту',
    profile_change_password: 'Изменить пароль',
    profile_change_position: 'Изменить должность',
    profile_change_company: 'Изменить компанию',
    profile_delete_profile: 'Удалить профиль',
    profile_are_you_sure: 'Вы уверены, что хотите удалить свой профиль? После удаления профиля вся фишинговая информация будет удалена без возможности восстановления.',
    profile_delete_hack: 'Message Delete Profile HACK',
    profile_accept: 'Принимая условия использования сайта, вы подтверждаете, что являетесь руководителем компании/организации или уполномоченным лицом на проведение проверок ее сотрудников. СТРОГО ЗАПРЕЩЕНО использовать сайт для атак на сторонние организации/людей, нанесения вреда, кражи персональных данных, распространения спама, вирусной информации!',
    profile_i_accept: 'Принимаю условия',
    profile_fill_info: 'Заполните информацию о себе',
    // placeholders
    profile_current_email: 'Ваша эл. почта',
    profile_new_email: 'Новая эл. почта',
    profile_repeat_email: 'Повторите новую почту',
    profile_password: 'Пароль',
    profile_new_password: 'Новый пароль',
    profile_repeat_password: 'Повторите новый пароль',
    profile_new_position: 'Новая должность',
    profile_new_company: 'Новое название компании',
    profile_firstname: 'Ваше имя',
    profile_lastname: 'Ваша фамилия',
    profile_position: 'Должность',
    profile_company: 'Компания',

    profile_toast_email_should_match: "Адреса электронной почты должны совпадать",
    profile_toast_email_changed: "Ваша электронная почта успешно изменена! При логине используйте новую электронную почту",
    profile_toast_email_incorrect: "Вы ввели неверную эл. почту или пароль. Попробуйте еще раз",
    profile_toast_password_changed: 'Пароль успешно изменен',
    profile_toast_password_incorrect: "Произошла ошибка, попробуйте еще раз",
    profile_toast_password_should_match: "Пароли должны совпадать",
    profile_toast_position_changed: "Позиция успешно изменена",
    profile_toast_company_changed: "Компания успешно изменена",
    profile_toast_profile_deleted: "Профиль успешно удален",
    profile_toast_profile_not_deleted: "Профиль не был удален",
    profile_toast_customerdata_changed: "Данные обновлены",
    profile_toast_session_expired: "Время сессии вышло",

    toast_error: "Что-то пошло не так",

    list_upload: 'Загрузите файл со списком сотрудников в формате CSV',
    list_specify: 'Определите названия заголовков колонок по примеру:',
    list_save: 'Сохранить',
    list_delete: 'Удалить',
    list_you_can_download: 'Вы можете скачать пример с нашего сайта и заполнить его по своему усмотрению:',
    list_show_list: 'Показать список',
    list_scenarios_locked: 'Сценарии заблокированы и не могут быть изменены пока кампания не завершится или будет перезагружена',
    list_scenarios_view: 'Вы можете увидеть прогресс активной кампании',
    list_delete_list: 'Удалить список',
    // component part
    list_message_empty_document: "Вы загрузили на сайт пустой документ",
    list_message_delete_error: "Ошибка при удалении! Пожалуйста попробуйте еще раз",
    list_message_invalid_csv: 'Неверные данные CSV',
    list_message_updated_success: "Персонал успешно обновлен.",
    list_message_updated_unsuccess: "Ошибка при обновлении персонала.",
    list_message_upload_success: "Персонал успешно загружен.",
    list_message_upload_failed: "Ошибка при загрузке персонала.",
    list_message_incorrect_csv: "Неверный CSV формат или отсутствуют колонки. Ожидалось: first_name, second_name, email, department",
    list_message_deleted_success: "Список персонала успешно удален.",
    list_message_deleted_not_deleted: "Список персонала не удален",
    list_message_member_deleted: "Элемент списка персонала успешно удален.",
    list_message_member_not_deleted: "Ошибка при удалении элемента списка персонала.",
    list_message_staff_download: "Загрузка документа успешна.",
    list_message_staff_not_download: "Ошибка при загрузке документа.",

    // render
    reports_you_want: 'Вы хотите отсортировать отчеты по дату когда сообщения были открыты?',
    reports_sorting_incorrect: 'Выбран неверный период для сортировки',
    reports_sort_by_script: 'Сортировка по скрипту:',
    reports_sort_by_department: 'Сортировка по отделу:',
    reports_search: 'Поиск',
    reports_general_statistics: 'Общая статистика',
    reports_successful_statistics: 'Статистика успешных случаев фишинга',
    reports_people_tested: 'Людей протестировано:',
    reports_emails_sent: 'Писем отправлено:',
    reports_successful_cases: 'Попалось человек:',
    reports_ignored_letters: 'Проигнорировано писем:',
    reports_download_xls: 'Загрузить как XLS',
    // component
    reports_no_reports_yet: "В настоящее время у вас еще нет активной кампании. Отчеты появятся здесь после запуска кампании. В настоящее время это заблокировано до начала кампании.",
    reports_results_found: "Результаты найдены. Загружаются.",
    reports_no_info: "Не найдена информация по заданным параметрам",

    scenary_scenario_saved: "Сценарий успешно сохранен",
    scenary_scenario_notsaved: "Сценарий не был сохранен",
    scenary_document_editable: "Документ теперь можно изменить",
    scenary_back_to_scripts: "Назад к сценариям",
    scenary_mandatory: "ОБЯЗАТЕЛЬНО!",
    scenary_can_edit: "Вы можете изменить тело этого письма, но помните что обязательно нужно использовать описанные _слова_",
    scenary_and: "и",
    scenary_start_of: "Начать кастомизировать блок",
    scenary_end_of: "Закончить кастомизировать блок",
    scenary_or_if: "Или если у вас есть готовый вариант можете его использовать",
    scenary_name_of: "Имя вашего письма;",
    scenary_when_the_actions: " Когда письма будут отправлены, слова будут заменены ссылками и могут выполнять дополнительные действия, например включение вложений. Ссылки, по которым нажимаются или открываются, используются для отслеживания действий получателя письма. Если вы удалите зарезервированные слова, получатель может получить неправильное письмо или письмо, которое сделает невозможным отслеживание фишинга.",
    scenary_enter_email: "Введите правильную эл. почту",
    scenary_popup_message: "Вы уверены что хотите сохранить? Это перезапишет предыдущий шаблок эл.почты.",
    scenary_edit: "Исправить",
    scenary_popup_save: "Сохранить",
    scenary_popup_cancel: "Отменить",

    scenarios_you_have: "Вы уже начали процесс фишинга. Из-за этого тексты скриптов недоступны для редактирования.",
    scenarios_launch: "ЗАПУСТИТЬ ФИШИНГ",
    scanarios_to_run: "чтобы запустить сценарий",
    scenarios_if_you: "Если Вы запустили сценарий, результаты появятся в этой секции",
    scenarios_reports: 'ОТЧЕТЫ',
    scenarios_soon: "скоро",
    scenarios_end_of: " конец пользовательского html-блока. При отправке писем слова будут заменены ссылками и могут выполнять дополнительные действия, например включение вложений. Ссылки, по которым нажимаются или открываются, используются для отслеживания действий получателя письма. Обязательно включите в свое электронное письмо одно из зарезервированных ключевых слов для отслеживания.",

    fake_password_images_updated: "Изображения успешно загружены",
    fake_password_images_notupdated: "Изображения не были загружены",
    fake_password_images_deleted: "Изображения успешно удалены",
    fake_password_images_notdeleted: "Изображения не были удалены",
    fake_password_images_saved: "Изображения успешно сохранены",
    fake_password_images_notsaved: "Изображения не были сохранены",
    fake_password_this_window: "Это окно загрузки не будет показано получателю письма.",
    fake_password_the_page: "Страница будет обновлена",
    fake_password_upload_logo: "Загрузите лого",
    fake_password_here: 'здесь',
    fake_password_upload_background: "Загрузите фон",
    fake_password_password_change: "Изменить пароль",
    fake_password_please_confirm: "Пожалуйста подтвердите детали для изменения пароля",
    fake_login_sso: "SSO Portal",
    fake_login_please_login: "Пожалуйста залогиньтесь",
}