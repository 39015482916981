import React, {useState} from "react";
import "./popup.css";

export default function Popup(props){
    const [chkbox, setChkbox] = useState(false);

    const handleChangeChk = () => {
        setChkbox(true);
    }

    const checkComponent = (e) => {
        let classClicked = e.target.className;

        if (classClicked === "popup-overlay") {
          props.cancelFunction();
        }
    }



    return (
        <>
            <div className="popup-overlay" onClick={(e) => checkComponent(e)}>
                <div className="custom_popup popup__ask-obout-send-letter-to-my-self">
                    <p className="popup-txt">{props.TextMessage}</p>
                    <div className="popup_btn-contaigner popup_btn-contaigner_send-letter-to-myself">
                        <button className="popup-yes-btn" onClick={() => props.okFunction(chkbox)}>
                            {props.okMessage}
                        </button>
                        <button className="popup-no-btn" onClick={props.cancelFunction}>
                            {props.cancelMessage}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}