import {FormattedMessage, useIntl} from "react-intl";
import {Loader} from "../Loader";
import {NotificationComponent} from "../../functions/functions";
import classNames from "classnames";
import React, {useEffect} from "react";
import man from "../../../assets/images/cabinet/profile/man.png";
import {NavLink} from "react-router-dom";
import {DangerZone} from "./DangerZone";
import setting from "../../../assets/images/cabinet/profile/setting.png";
import Accordion from "react-bootstrap/Accordion";

export const Render = (props) => {
    const intl = useIntl();
    
    return (
         <>
         <NotificationComponent position="top-right"/>
          {props.clientInfo ? (
            props.clientInfo.acceptetruls ?
                <div className="Profile profile-container">
                    <div className="profile_continious-client ">
                      <div className="personalInfo">
                          {props.clientInfo ?
                              <div className="client_info">
                                <div className="client-img-and-name">
                                  <div className="client-img_contaigner">
                                    <img src={man} alt="client_img" />
                                  </div>

                                  <h4 className="client-name bold">
                                    {props.clientInfo.customername}
                                    <br />
                                    {props.clientInfo.customersecondname}
                                  </h4>
                                </div>
                                <p>
                                  <span className="bold">Position: </span>
                                  {props.clientInfo.customertittle}
                                </p>
                                <p>
                                  <span className="bold">Company: </span>
                                  {props.clientInfo.customercompany}
                                </p>
                              </div>
                              :
                              null
                          }
                          {props.isScenarioActive && !props.isCampaignFinished ?
                          <div className="phishing-started_information-block">
                            <marquee behavior="scroll" direction="right">
                              <i className="fas fa-fish"></i> <FormattedMessage id="profile_marquee_launched"/>
                              <i className="fas fa-fish"></i>
                            </marquee>{" "}
                          </div>
                              :
                              null
                          }
                          {props.isCampaignFinished === true ?
                          <div className="phishing-started_information-block">
                            <marquee behavior="scroll" direction="right">
                              {" "}
                              <FormattedMessage id="profile_marquee_finished"/> {props.campaignDateEnd}
                            </marquee>{" "}
                          </div>
                              :
                              null
                          }
                          <div className="layout statisticAndSettingContainer ">
                              {props.statisticsPhishing && props.statisticsInfo ? (
                                <div className="statisticInformation">
                                  <p>
                                    Emails sent: {props.statisticsPhishing.amountssendedLetters}{" "}
                                  </p>

                                  <p>
                                    People on the checklist:{" "}
                                    {props.statisticsPhishing.amountsOfPeople}{" "}
                                  </p>

                                  {props.scenariosSubjects ? (
                                    <p>
                                      <b><FormattedMessage id="profile_quantity_phished"/></b>
                                    </p>
                                  ) : null}

                                  {props.scenariosSubjects ? (
                                    <p>
                                      {props.scenariosSubjects.scenariosubject1}:{" "}
                                      {props.statisticsPhishing.date1}
                                    </p>
                                  ) : null}
                                  {props.scenariosSubjects ? (
                                    <p>
                                      {props.scenariosSubjects.scenariosubject2}:{" "}
                                      {props.statisticsPhishing.date2}
                                    </p>
                                  ) : null}
                                  {props.scenariosSubjects ? (
                                    <p>
                                      {props.scenariosSubjects.scenariosubject3}:{" "}
                                      {props.statisticsPhishing.date3}
                                    </p>
                                  ) : null}

                                  <NavLink to="/cabinet/reports" className="show-reports">
                                    <FormattedMessage id="profile_details"/>
                                  </NavLink>
                                </div>
                              ) : null}
                              <div className="client_setting ">
                                  <div className="client_setting_name ">
                                      <h5 className="bold"><FormattedMessage id="profile_settings"/></h5>
                                      <img
                                          src={setting}
                                          alt="setting"
                                          className="setting_img"
                                      />
                                  </div>
                                      <Accordion>
                                           <Accordion.Item eventKey="0">
                                               <Accordion.Header>
                                                  <FormattedMessage id="profile_change_email"/>
                                               </Accordion.Header>
                                               <Accordion.Body>
                                                   <div className="profile__container_side profile__container_mail">
                                                       <div className="profile__container_side_content-block">
                                                           <form className="profile-form" onSubmit={props.emailForm.handleSubmit(props.updateEmail, props.submitError)} method="POST">
                                                               <div className="form-group">
                                                                   <input type="text"
                                                                          className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                              {
                                                                                  'input-success': !props.emailForm.formState.errors['email'] && props.emailForm.formState.dirtyFields['email'],
                                                                                  'input-unsuccess': props.emailForm.formState.errors['email'] && props.emailForm.formState.dirtyFields['email'],
                                                                              }
                                                                          )}
                                                                          {...props.emailForm.register("email", {
                                                                            required: "Email is required",
                                                                            validate: props.isEmail,
                                                                          })}
                                                                          placeholder={intl.formatMessage({id: 'profile_current_email'})}
                                                                   />
                                                               </div>
                                                               <div className="form-group">
                                                                   <input type="text"
                                                                          className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                              {
                                                                                  'input-success': !props.emailForm.formState.errors['newEmail'] && props.emailForm.formState.dirtyFields['newEmail'],
                                                                                  'input-unsuccess': props.emailForm.formState.errors['newEmail'] && props.emailForm.formState.dirtyFields['newEmail'],
                                                                              }
                                                                          )}
                                                                          {...props.emailForm.register("newEmail", {
                                                                            required: "Email is required",
                                                                              validate: props.isEmail,
                                                                          })}
                                                                          placeholder={intl.formatMessage({ id: 'profile_new_email' })}
                                                                   />
                                                               </div>
                                                               <div className="form-group">
                                                                   <input type="text"
                                                                          className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                              {
                                                                                  'input-success': !props.emailForm.formState.errors['newEmailRepeat'] && props.emailForm.formState.dirtyFields['newEmailRepeat'],
                                                                                  'input-unsuccess': props.emailForm.formState.errors['newEmailRepeat'] && props.emailForm.formState.dirtyFields['newEmailRepeat'],
                                                                              }
                                                                          )}
                                                                          {...props.emailForm.register("newEmailRepeat", {
                                                                            required: "Email is required",
                                                                              validate: props.isEmail,
                                                                          })}
                                                                          placeholder={intl.formatMessage({ id: 'profile_repeat_email' })}
                                                                   />
                                                               </div>
                                                               <div className="form-group">
                                                                   <input type="password"
                                                                          className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                              {
                                                                                  'input-success': !props.emailForm.formState.errors['password'] && props.emailForm.formState.dirtyFields['password'],
                                                                                  'input-unsuccess': props.emailForm.formState.errors['password'] && props.emailForm.formState.dirtyFields['password'],
                                                                              }
                                                                          )}
                                                                          placeholder={intl.formatMessage({ id: 'profile_password' })}
                                                                          {...props.emailForm.register("password", {
                                                                            required: "Password is required",
                                                                              validate: props.isPassword,
                                                                          })}
                                                                   />
                                                               </div>

                                                               <input type="submit"
                                                                      value={intl.formatMessage({id: 'profile_send'})}
                                                                      className="submit__btn btn__changeinfo "
                                                               />
                                                           </form>
                                                       </div>
                                                   </div>
                                               </Accordion.Body>
                                           </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <FormattedMessage id="profile_change_password"/> <i className="fas fa-angle-down"></i>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="profile__container_side profile__container_password">
                                                        <div className="profile__container_side_content-block">
                                                            <form className="profile-form" onSubmit={props.passwordForm.handleSubmit(props.updatePassword, props.submitError)} method="POST">
                                                                <div className="form-group  ">
                                                                    <input type="password"
                                                                           className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                               {
                                                                                   'input-success': !props.passwordForm.formState.errors['password'] && props.passwordForm.formState.dirtyFields['password'],
                                                                                   'input-unsuccess': props.passwordForm.formState.errors['password'] && props.passwordForm.formState.dirtyFields['password'],
                                                                               }
                                                                           )}
                                                                           {...props.passwordForm.register("password", {
                                                                               required: "Password is required",
                                                                               validate: props.isPassword,
                                                                           })}
                                                                           placeholder={intl.formatMessage({id: 'profile_password'})}
                                                                    />
                                                                </div>

                                                                <div className="form-group  ">
                                                                    <input type="password"
                                                                           className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                               {
                                                                                   'input-success': !props.passwordForm.formState.errors['newPassword'] && props.passwordForm.formState.dirtyFields['newPassword'],
                                                                                   'input-unsuccess': props.passwordForm.formState.errors['newPassword'] && props.passwordForm.formState.dirtyFields['newPassword'],
                                                                               }
                                                                           )}
                                                                           {...props.passwordForm.register("newPassword", {
                                                                               required: "Password is required",
                                                                               validate: props.isPassword,
                                                                           })}
                                                                           placeholder={intl.formatMessage({id: 'profile_new_password'})}
                                                                    />
                                                                </div>

                                                                <div className="form-group  ">
                                                                    <input type="password"
                                                                           className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                               {
                                                                                   'input-success': !props.passwordForm.formState.errors['repeatPassword'] && props.passwordForm.formState.dirtyFields['repeatPassword'],
                                                                                   'input-unsuccess': props.passwordForm.formState.errors['repeatPassword'] && props.passwordForm.formState.dirtyFields['repeatPassword'],
                                                                               }
                                                                           )}
                                                                           {...props.passwordForm.register("repeatPassword", {
                                                                               required: "Password is required",
                                                                               validate: props.isPassword,
                                                                           })}
                                                                           placeholder={intl.formatMessage({id: 'profile_repeat_password'})}
                                                                    />
                                                                </div>

                                                                <input type="submit"
                                                                       value={intl.formatMessage({id: 'profile_send'})}
                                                                       className="submit__btn btn__changeinfo "
                                                                />

                                                            </form>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                          <Accordion.Item eventKey="2">
                                              <Accordion.Header>
                                                  <FormattedMessage id="profile_change_position"/> <i
                                                  className="fas fa-angle-down"></i>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                  <div className="profile__container_side profile__container_tittle">
                                                      <div className="profile__container_side_content-block">
                                                      <form className="profile-form" onSubmit={props.positionForm.handleSubmit(props.updatePosition, props.submitError)}
                                                                method="POST">
                                                          <div className="form-group  ">
                                                              <input type="text"
                                                                     className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                         {
                                                                             'input-success': !props.positionForm.formState.errors['newPosition'] && props.positionForm.formState.dirtyFields['newPosition'],
                                                                             'input-unsuccess': props.positionForm.formState.errors['newPosition'] && props.positionForm.formState.dirtyFields['newPosition'],
                                                                         }
                                                                     )}
                                                                     {...props.positionForm.register("newPosition", {
                                                                         required: "New position is required",
                                                                         validate: props.isText,
                                                                     })}
                                                                     placeholder={intl.formatMessage({id: 'profile_new_position'})}
                                                              />
                                                          </div>

                                                          <div className="form-group  ">
                                                              <input type="password"
                                                                     className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                         {
                                                                             'input-success': !props.positionForm.formState.errors['password'] && props.positionForm.formState.dirtyFields['password'],
                                                                             'input-unsuccess': props.positionForm.formState.errors['password'] && props.positionForm.formState.dirtyFields['password'],
                                                                         }
                                                                     )}
                                                                     {...props.positionForm.register("password", {
                                                                         required: "Password is required",
                                                                         validate: props.isPassword,
                                                                     })}
                                                                     placeholder={intl.formatMessage({id: 'profile_password'})}
                                                              />
                                                          </div>

                                                          <input type="submit"
                                                                 value={intl.formatMessage({id: 'profile_send'})}
                                                                 className="submit__btn btn__changeinfo "
                                                          />
                                                      </form>
                                                      </div>
                                                  </div>
                                              </Accordion.Body>
                                          </Accordion.Item>

                                          <Accordion.Item eventKey="3">
                                              <Accordion.Header>
                                                  <FormattedMessage id="profile_change_company"/> <i
                                                  className="fas fa-angle-down"></i>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                  <div className="profile__container_side profile__container_tittle">
                                                      <div className="profile__container_side_content-block">
                                                          <form className="profile-form"
                                                                onSubmit={props.companyForm.handleSubmit(props.updateCompany, props.submitError)}
                                                                method="POST">
                                                              <div className="form-group  ">
                                                                  <input type="text"
                                                                         className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                             {
                                                                                 'input-success': !props.companyForm.formState.errors['newCompany'] && props.companyForm.formState.dirtyFields['newCompany'],
                                                                                 'input-unsuccess': props.companyForm.formState.errors['newCompany'] && props.companyForm.formState.dirtyFields['newCompany'],
                                                                             }
                                                                         )}
                                                                         {...props.companyForm.register("newCompany", {
                                                                             required: "New company is required",
                                                                             validate: props.isText,
                                                                         })}
                                                                         placeholder={intl.formatMessage({id: 'profile_new_company'})}
                                                                  />
                                                              </div>

                                                              <div className="form-group  ">
                                                                  <input type="password"
                                                                         className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                             {
                                                                                 'input-success': !props.companyForm.formState.errors['password'] && props.companyForm.formState.dirtyFields['password'],
                                                                                 'input-unsuccess': props.companyForm.formState.errors['password'] && props.companyForm.formState.dirtyFields['password'],
                                                                             }
                                                                         )}
                                                                         {...props.companyForm.register("password", {
                                                                             required: "Password is required",
                                                                             validate: props.isPassword,
                                                                         })}
                                                                         placeholder={intl.formatMessage({id: 'profile_password'})}
                                                                  />
                                                              </div>

                                                              <input type="submit"
                                                                     value={intl.formatMessage({id: 'profile_send'})}
                                                                     className="submit__btn btn__changeinfo "
                                                              />
                                                          </form>
                                                      </div>
                                                  </div>
                                              </Accordion.Body>
                                          </Accordion.Item>

                                          <Accordion.Item eventKey="4">
                                              <Accordion.Header>
                                                  <FormattedMessage id="profile_delete_profile"/><i
                                                  className="fas fa-angle-down"></i>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                  <div className="profile__container_side profile__container_tittle">
                                                      <div className="profile__container_side_content-block">
                                                          <form className="profile-form"
                                                                onSubmit={props.deleteForm.handleSubmit(props.deleteProfile, props.submitError)}
                                                                method="POST">
                                                              <div className="form-group  ">
                                                                  <input type="password"
                                                                         className={classNames("input__form form-control input__form_password input__form_password_login",
                                                                             {
                                                                                 'input-success': !props.deleteForm.formState.errors['password'] && props.deleteForm.formState.dirtyFields['password'],
                                                                                 'input-unsuccess': props.deleteForm.formState.errors['password'] && props.deleteForm.formState.dirtyFields['password'],
                                                                             }
                                                                         )}
                                                                         {...props.deleteForm.register("password", {
                                                                             required: "Password is required",
                                                                             validate: props.isPassword,
                                                                         })}
                                                                         placeholder={intl.formatMessage({id: 'profile_password'})}
                                                                  />
                                                              </div>
                                                              <input type="submit"
                                                                     value={intl.formatMessage({id: 'profile_delete_profile'})}
                                                                     className="submit__btn btn_delete-profile "/>
                                                          </form>
                                                      </div>
                                                  </div>
                                              </Accordion.Body>
                                          </Accordion.Item>

                                      </Accordion>

                              </div>
                          </div>
                          <DangerZone
                              isCampaignFinishedFunc={props.isCampaignFinishedFunc}
                              isScenarioActiveFunc={props.isScenarioActiveFunc}
                          />
                      </div>
                    </div>
                </div>
                :
                <>
                    <div className="Profile profile-container">
                        <div className="profile_new-client">
                            <div className="message_accept-ruls">
                                <h4>ATTENTION PLEASE!</h4>
                                <p><FormattedMessage id="profile_accept"/></p>
                                <div className="checkboxAccepted_container">
                                    <input type="checkbox"
                                           name="accepted"
                                           id="accepted"
                                           className="checkboxAccepted"
                                           ref={props.acceptRulesCheckbox}
                                    />
                                    <label htmlFor="accepted"><FormattedMessage id="profile_i_accept"/></label>
                                    <p className={classNames("errorMassage checkboxMassage", {none: !props.rulesNotAccepted})}>
                                        You did not agree to the terms of use of the site
                                    </p>

                                    <div className="client-personal-data">
                                        <label htmlFor="client-data">
                                            <FormattedMessage id="profile_fill_info"/>
                                        </label>
                                        <br/>
                                        <span className="help-block errorMassage emptyFormMassage">
                                </span>
                                        <form className="client-data_form"
                                              onSubmit={props.newUserForm.handleSubmit(props.sendNewClientInfo, props.submitError)}
                                              method="POST">
                                            <input type="text"
                                                   className={classNames("input__form form-control input__form_password input__form_password_login",
                                                       {
                                                           'input-success': !props.newUserForm.formState.errors['name'] && props.newUserForm.formState.dirtyFields['name'],
                                                           'input-unsuccess': props.newUserForm.formState.errors['name'] && props.newUserForm.formState.dirtyFields['name'],
                                                       }
                                                   )}
                                                   {...props.newUserForm.register("name", {
                                                       required: "Name is required",
                                                       validate: props.isText,
                                                   })}
                                                   placeholder={intl.formatMessage({id: 'profile_firstname'})}
                                            />

                                            <input type="text"
                                                   className={classNames("input__form form-control input__form_password input__form_password_login",
                                                       {
                                                           'input-success': !props.newUserForm.formState.errors['secondName'] && props.newUserForm.formState.dirtyFields['secondName'],
                                                           'input-unsuccess': props.newUserForm.formState.errors['secondName'] && props.newUserForm.formState.dirtyFields['secondName'],
                                                       }
                                                   )}
                                                   {...props.newUserForm.register("secondName", {
                                                       required: "second name is required",
                                                       validate: props.isText,
                                                   })}
                                                   placeholder={intl.formatMessage({id: 'profile_lastname'})}
                                            />

                                            <input type="text"
                                                   className={classNames("input__form form-control input__form_password input__form_password_login",
                                                       {
                                                           'input-success': !props.newUserForm.formState.errors['position'] && props.newUserForm.formState.dirtyFields['position'],
                                                           'input-unsuccess': props.newUserForm.formState.errors['position'] && props.newUserForm.formState.dirtyFields['position'],
                                                       }
                                                   )}
                                                   {...props.newUserForm.register("position", {
                                                       required: "position is required",
                                                       validate: props.isText,
                                                   })}
                                                   placeholder={intl.formatMessage({id: 'profile_position'})}
                                            />

                                            <input type="text"
                                                   className={classNames("input__form form-control input__form_password input__form_password_login",
                                                       {
                                                           'input-success': !props.newUserForm.formState.errors['company'] && props.newUserForm.formState.dirtyFields['company'],
                                                           'input-unsuccess': props.newUserForm.formState.errors['company'] && props.newUserForm.formState.dirtyFields['company'],
                                                       }
                                                   )}
                                                   {...props.newUserForm.register("company", {
                                                       required: "company is required",
                                                       validate: props.isText
                                                   })}
                                                   placeholder={intl.formatMessage({id: 'profile_company'})}
                                            />
                                             <input type="submit"
                                                                      value={intl.formatMessage({id: 'profile_send'})}
                                                                      className="submit__btn btn__changeinfo "
                                                               />
                                
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
          ) : (
              <Loader/>
          )}
         </>
    )
}