import React from "react";
import {FormattedMessage} from "react-intl";
import {motion} from "framer-motion";

const Phishing = () => {
  return (
    <div className="phishing">
      <div className="phishing__container container__fixed ">
        <h2><FormattedMessage id="mp_phishing_whatis"/></h2>
        <motion.p className="paragraph__text" initial={{x: 200, opacity: 0}} transition={{duration: 0.6, delay: 0.3}} whileInView={{x: 0, opacity: 1}}>
          <FormattedMessage id="mp_phishing_text"/>
        </motion.p>
      </div>
    </div>
  );
};

export default Phishing;