import React from "react";
import screenabout from "../../../assets/images/mainpage/aboutsite/screenabout.png";
import {FormattedMessage} from "react-intl";
import {motion} from "framer-motion";

const Aboutsite = () => {
  return (
    <div className="aboutsite ">
      <div className="aboutsite__container container__fixed layout">
        <div className="aboutsite__txt">
          <motion.h2 initial={{x: -200, opacity: 0}} transition={{duration: 0.3, delay: 0.3}} whileInView={{x: 0, opacity: 1}}><FormattedMessage id="mp_about_howcan"/></motion.h2>
          <motion.p className="paragraph__text" initial={{x: -200, opacity: 0}} transition={{duration: 0.3, delay: 0.6}} whileInView={{x: 0, opacity: 1}}>
            <FormattedMessage id="mp_about_text"/>
          </motion.p>
        </div>

        <div className="aboutsite__right">
          <motion.img src={screenabout} className="aboutsite__img" alt="about" initial={{x: 200, opacity: 0}} transition={{duration: 0.8, delay: 0.2}} whileInView={{x: 0, opacity: 1}}/>
        </div>
      </div>
    </div>
  );
};

export default Aboutsite;
