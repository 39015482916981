import {NotificationComponent} from "../../../functions/functions";
import {FormattedMessage} from "react-intl";
import FileBase64 from "react-file-base64";
import React from "react";
import classNames from "classnames";

export const FakePasswordRender = (props) => {
    return (
        <>
            <NotificationComponent position="top-right"/>
            <div className="confirm-reg" style={{backgroundImage: props.background}}>
                <div className="file-uploder_contaigner">
                    <button type="submit" className={classNames("toggle-help-window hide-help-window", {none: props.activeWindow})}
                            onClick={props.hideWindow}>
                        <i className="far fa-window-minimize"></i>
                    </button>
                    <button type="submit" className={classNames("toggle-help-window show-help-window", {none: !props.activeWindow})}
                            onClick={props.showWindow}>
                        <i className="far fa-window-maximize"></i>
                    </button>
                    <p className={classNames("fake-page_info", {none: props.activeWindow})}><FormattedMessage id="fake_password_this_window"/></p>
                    <div className={classNames("file-uploder_input_contaigner", {none: props.activeWindow})}>
                        <p>
                            <FormattedMessage id="fake_password_the_page"/><a
                            href={process.env.REACT_APP_CUSTOMER_FAKE_SITES_PASSCHANGE}><FormattedMessage id="fake_password_here"/></a>
                        </p>
                        <p>{props.message}</p>

                        <label><FormattedMessage id="fake_password_upload_logo"/></label>
                        <FileBase64 multiple={true} onDone={props.getLogo}/>
                        <label><FormattedMessage id="fake_password_upload_background"/></label>
                        <FileBase64 multiple={true} onDone={props.getBg}/>
                        <button type="submit" className="confirm-reg-btn btn-save" value="Submit"
                                onClick={props.saveImages}>
                            <FormattedMessage id="list_save"/>
                        </button>
                        <button type="submit" className="confirm-reg-btn btn-delete" value="Submit"
                                onClick={props.deleteImages}>
                            <FormattedMessage id="list_delete"/>
                        </button>
                    </div>
                </div>

                <div className="logo">
                    <img className="logo-example_img" src={props.logo}/>
                </div>

                <div className="form-contaigner">
                    <div className="form-contaigner_top">
                        <h2 className="fakepage-admin-h">Admin page</h2>
                        <h4><FormattedMessage id="fake_password_password_change"/></h4>
                        <h5><FormattedMessage id="fake_password_please_confirm"/></h5>
                    </div>
                    <div className="form-contaigner_bottom">
                        <form className="form">
                            <input id="username" className="form-inputfield" placeholder="Username" type="text"
                                   required/>
                            <br/>

                            <input id="oldpassword" className="form-inputfield" placeholder="Old password"
                                   type="password"/>
                            <br/>
                            <input id="newpassword" className="form-inputfield" placeholder="New password"
                                   type="password"/>
                            <br/>
                            <input className="form-button" type="button" value="Continue"/>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}