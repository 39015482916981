import React from 'react';
import {NotificationComponent} from "../functions/functions";
import {NavLink} from "react-router-dom";
import remove from "../../assets/images/login/remove.png";
import {FormattedMessage, useIntl} from "react-intl";
import classNames from "classnames";

export const Render = (props) => {
    const intl = useIntl();

    return (
        <>
            <NotificationComponent position="top-right"/>
            <div className="autorisation">
                <div className="linkToMainpage">
                    <NavLink className="close-login" to="/">
                        <img src={remove} className="close-login_img" alt="close-login"/>
                    </NavLink>
                </div>
                <div className="auth__background">
                    <div className="block-modal-test">
                        <div className={classNames("form-slider", {'form-slider-switched': props.switchToReg})}
                             ref={props.formSlider}>
                            <div className="left-50">

                                <div className="login__container">
                                    <form method="POST" className="login_form example"
                                        onSubmit={props.loginForm.handleSubmit(props.submitLogin, props.submitError)}
                                    >
                                        <div className="inputBlock">
                                            <div className="login__block">
                                                <input
                                                    className={classNames("input__form form-control input__form_email input__form_email_for_login", {
                                                        'input-success': !props.loginForm.formState.errors['email'] && props.loginForm.formState.dirtyFields['email'],
                                                        'input-unsuccess': props.loginForm.formState.errors['email'] && props.loginForm.formState.dirtyFields['email'],
                                                    })}
                                                    {...props.loginForm.register("email", {
                                                       required: intl.formatMessage({id: 'toast_fields_required'}),
                                                       validate: props.isEmail,
                                                   })}
                                                    placeholder={intl.formatMessage({id: 'login_email'})}
                                                />
                                            </div>

                                            <div className="login__block">
                                                <input
                                                    type="password"
                                                    className={classNames("input__form form-control input__form_password input__form_password_login", {
                                                        'input-success': !props.loginForm.formState.errors['password'] && props.loginForm.formState.dirtyFields['password'],
                                                        'input-unsuccess': props.loginForm.formState.errors['password'] && props.loginForm.formState.dirtyFields['password'],
                                                    })}
                                                    {...props.loginForm.register("password", {
                                                       required: intl.formatMessage({id: 'toast_fields_required'}),
                                                       validate: props.isPassword,
                                                   })}
                                                    placeholder={intl.formatMessage({id: 'login_password'})}
                                                />
                                            </div>
                                        </div>

                                        <input type="submit" className="submit__btn btn__login" value={intl.formatMessage({id: 'login_login'})}/>
                                    </form>
                                    <a onClick={props.toggleSwitchToReg} className=" btn__show btn__show_registration">
                                        <FormattedMessage id="login_registration"/>
                                    </a>
                                </div>
                            </div>
                            <div className="right-50">
                                <div className="login__container">
                                    <form method="POST" onSubmit={props.registerForm.handleSubmit(props.submitRegister, props.submitError)}>
                                        <div className="inputBlock">
                                            <div className="registration__block">
                                                <input
                                                    className={classNames("input__form form-control input__form_email input__form_email_for_login" , {
                                                        'input-success': !props.registerForm.formState.errors['email'] && props.registerForm.formState.dirtyFields['email'],
                                                        'input-unsuccess': props.registerForm.formState.errors['email'] && props.registerForm.formState.dirtyFields['email'],
                                                    })}
                                                    {...props.registerForm.register("email", {
                                                       required: intl.formatMessage({id: 'toast_fields_required'}),
                                                       validate: props.isEmail,
                                                   })}
                                                    placeholder={intl.formatMessage({id: 'login_email'})}
                                                />
                                            </div>
                                            <div className="registration__block">
                                                <input
                                                    type="password"
                                                    className={classNames("input__form form-control input__form_password input__form_password_login", {
                                                        'input-success': !props.registerForm.formState.errors['password'] && props.registerForm.formState.dirtyFields['password'],
                                                        'input-unsuccess': props.registerForm.formState.errors['password'] && props.registerForm.formState.dirtyFields['password'],
                                                    })}
                                                    {...props.registerForm.register("password", {
                                                       required: intl.formatMessage({id: 'toast_fields_required'}),
                                                       validate: props.isPassword,
                                                    })}
                                                    placeholder={intl.formatMessage({id: 'login_password'})}
                                                />
                                            </div>

                                            <div className="registration__block">
                                                <input
                                                    className={classNames("input__form form-control input__form_password input__form_password_login", {
                                                        'input-success': !props.registerForm.formState.errors['key'] && props.registerForm.formState.dirtyFields['key'],
                                                        'input-unsuccess': props.registerForm.formState.errors['key'] && props.registerForm.formState.dirtyFields['key'],
                                                    })}
                                                    {...props.registerForm.register("key", {
                                                       required: intl.formatMessage({id: 'toast_fields_required'}),
                                                       validate: props.isText,
                                                    })}
                                                    placeholder={intl.formatMessage({id: 'login_regkey'})}
                                                />
                                            </div>
                                        </div>
                                        <input type="submit" className=" submit__btn registration__btn" value={intl.formatMessage({id: 'login_registration'})}/>
                                    </form>
                                </div>
                                <a onClick={props.toggleSwitchToReg} className="btn__show back_to_login">
                                    <i className="fas fa-long-arrow-alt-left"></i><FormattedMessage id="login_back"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}