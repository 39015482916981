import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";

import {Scenary} from "../scenary/Scenary";
import {FormattedMessage} from "react-intl";

export const Scenaries = (props) => {
    const [isClicked, setIsClicked] = useState(false);
    const [scenarioInfo, setScenarioInfo] = useState({});

    useEffect(() => {
        props.selectedScenarios();
    }, [])

    const getScenario = (scenarioId) => {
        let data = JSON.stringify({
          scenarioid: scenarioId,
        });
        axios
          .post(`${props.host}getscenario`, data, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                  setScenarioInfo(response.data.result[0]);
                  setIsClicked(true);
              } else {
                    console.log(response.data);
                   console.log("getScenario failed in Scenaries component");
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }
    const toggleIsClicked = () => { props.selectedScenarios(); setIsClicked(!isClicked)}

    return (
        <>
            {isClicked === false ?
                <div className="scenaries">
                    <div className="scenaries__list">
                        <div>
                            {props.isActive ? (
                                <div className="informationBlock">
                                    <div className="startphishing-warning-about-empty-stafflist errorMassage">
                                        <p>
                                            <FormattedMessage id="scenarios_you_have"/> <NavLink to="/cabinet/startphishing">
                                            <FormattedMessage id="scenarios_launch"/>
                                        </NavLink><FormattedMessage id="scenarios_to_run"/>
                                        </p>
                                        <p>
                                            <FormattedMessage id="scenarios_if_you"/><NavLink to="/cabinet/reports"><FormattedMessage id="scenarios_reports"/></NavLink><FormattedMessage id="scenarios_soon"/>.
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className="information-block">
                                    <div className="information-block_top">
                                        <h5>MANDATORY!</h5>
                                    </div>
                                    <div className="information-block_bottom">
                                        <p>
                                            <FormattedMessage id="scenary_can_edit"/><span
                                            className="bold-font">_Firstname_ _Lastname_</span> <FormattedMessage id="scenary_and"/>
                                            <span className="bold-font">_linkinfodesk_</span>, <span
                                            className="bold-font">_linkchangepassword_</span>,<span
                                            className="bold-font">_linklogin_</span>,{" "}
                                            <span className="bold-font">_datetoday__</span>,<span
                                            className="bold-font">_date3months_ </span>, <span
                                            className="bold-font">_attachmentdefault_</span>
                                            <span className="bold-font">_attachmentlnk_</span> <FormattedMessage id="scenary_or_if"/>
                                            <span
                                                className="bold-font">_attachmentcustom_=<FormattedMessage id="scenary_name_of"/>;"</span>
                                            <span className="bold-font">_htmlblockstart_</span><FormattedMessage id="scenary_start_of"/>
                                            <span className="bold-font">_htmlblockend_</span> <FormattedMessage id="scenarios_end_of"/>
                                            <FormattedMessage id="scenarios_end_of"/>{" "}
                                        </p>
                                    </div>
                                </div>
                            )}

                            <table className="scenaries__list__table ">
                                <tbody>
                                {props.arrayScenarioIdAndSubject.map((scenario) => (
                                <tr key={scenario.scenarioid}>
                                    <td className="scenaries__list_block"
                                        onClick={() => getScenario(scenario.scenarioid)}>
                                        {scenario.subject}
                                    </td>
                                </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                :
                <Scenary

                  isActive={props.isActive}
                  host={props.host}
                  toggleIsClicked={toggleIsClicked}

                  fromadress={scenarioInfo.fromadress}
                  scenarioBody={scenarioInfo.body}
                  scenarioSubject={scenarioInfo.subject}
                  scenarioId={scenarioInfo.scenarioid}
                  scenarioaddedfile={scenarioInfo.addedfile}
                  scenriofilename={scenarioInfo.nameofaddedfile}
                  scenariotype={scenarioInfo.scenariotype}
                />
            }
        </>
    )
}
