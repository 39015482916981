import React from "react";
import manWithCash from "../../../assets/images/mainpage/humanrisks/manWithCash.png";
import {FormattedMessage} from "react-intl";
import {motion} from "framer-motion";

const Humanrisks = () => {
  return (
    <div className="humanrisks container__fixed ">
      <div className="humanrisks__block layout">
        <motion.img src={manWithCash} className="manWithCash" alt="manWithCash"  initial={{x: -200, opacity: 0}} transition={{duration: 0.8, delay: 0.2}} whileInView={{x: 0, opacity: 1}}/>
        <motion.div className="humanrisks__block__txt">
          <p>
            <FormattedMessage id="mp_humanrisks_notonly"/>
            <br />
            <FormattedMessage id="mp_humanrisks_often"/>
            <br />
            <FormattedMessage id="mp_humanrisks_notonly"/>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Humanrisks;