import React, {useEffect, useRef, useState} from "react";
import cookie from "react-cookies";
import axios from "axios";
import {Render} from "./Render";
import './Profile.css'
import {toast} from "react-toastify";
import {Logout} from "../../functions/functions";
import {useForm} from "react-hook-form";
import {useIntl} from "react-intl";


export const Profile = (props) => {
    // props state
    const [clientInfo, setClientInfo] = useState(props.clientInfo || null);
    const [scenariosSubjects, setScenariosSubjects] = useState(props.scenariosSubjects || null);
    const [statisticsInfo, setStatisticsInfo] = useState(props.statisticinfo || null);
    const [statisticsPhishing, setStatisticsPhishing] = useState(props.statisicPhishing || null);
    const [isScenarioActive, setIsScenarioActive] = useState(props.isScenarioActive || null);

    // new user states
    const [rulesNotAccepted, setRulesNotAccepted] = useState(false);
    const acceptRulesCheckbox = useRef();

    const [isCampaignFinished, setIsCampaignFinished] = useState(null);
    const [campaignDateEnd, setCampaignDateEnd] = useState(null);

    const [inputRegex, setInputRegex] = useState({
        email: new RegExp("^[a-zA-Z]+[\\w\\d]*@[a-zA-Z\\d]+\\.[a-zA-Z]+$"),
        password: new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,16}$"),
        string: new RegExp("^[A-Za-zа-яА-Я]{3,}$"),
    })
    const emailForm = useForm({
        mode: 'onChange',
    })
    const passwordForm = useForm({mode: 'onChange',})
    const positionForm = useForm({mode: 'onChange',})
    const companyForm = useForm({mode: 'onChange',})
    const deleteForm = useForm({mode: 'onChange',})
    const newUserForm = useForm({mode: 'onChange',})

    const intl = useIntl();

    useEffect(() => {
        const fetchData = async () => {

            await isCampaignFinishedFunc();
            await isScenarioActiveFunc();
        };

        fetchData();
    }, []);

    const updateEmail = async data => {

        if(data.newEmail !== data.newEmailRepeat){
            toast.warning(intl.formatMessage({id: 'profile_toast_email_should_match'}));
            return;
        }

        const emailData = {
            Email: data.email,
            NewEmail: data.newEmail,
            Password: data.password
        }

        try{
            const response = await axios.post(
                `${props.host}updatemail`,
                emailData,
                {headers: {
                    "Content-Type": "application/json", xtoken: cookie.load("xtoken"),
                    }})

            if(response.data.status === "success"){
                toast.success(intl.formatMessage({id: 'profile_toast_email_changed'}))
                emailForm.reset();
            } else if(response.data.status === "failed"){
                toast.error(intl.formatMessage({id: 'profile_toast_email_incorrect'}))
            }
        } catch (error) {
            toast.error(intl.formatMessage({id: 'toast_error'}));
            console.log('Error while changing email', error)
        }
    };

    const updatePassword = async (data) => {

         if(data.passwordNewPassword !== data.passwordRepeatPassword){
            toast.warning(intl.formatMessage({id: 'profile_toast_password_should_match'}));
            return;
         }
         const passwordData = {
             CurrentPassword: data.password,
             NewPassword: data.newPassword,
         }

         try{
             const response = await axios.post(
                    `${props.host}updatepassword`,
                    passwordData,
                    {headers: {
                    "Content-Type": "application/json", xtoken: cookie.load("xtoken"),
                    }})

                if(response.data.status === "success"){
                    toast.success(intl.formatMessage({id: 'profile_toast_password_changed'}));
                    passwordForm.reset();
                } else if(response.data.status === "failed"){
                    console.log(response.data)
                    toast.warning(intl.formatMessage({id: 'profile_toast_password_incorrect'}));
                }

            } catch(error){
                toast.warning(intl.formatMessage({id: 'toast_error'}))
                console.log(error);
            }
    }

    const updatePosition = async data => {

        try{
            const positionData = JSON.stringify({
              password: data.password,
              newTittle: data.newPosition,
            });
            const response = await axios.post(
                `${props.host}updatetittle`,
                positionData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        xtoken: cookie.load("xtoken"),
                    }
                })

            if(response.data.status === 'success'){
                await updatePage();
                positionForm.reset();
                toast.success(intl.formatMessage({id: 'profile_toast_position_changed'}));
            } else{
                toast.warning(intl.formatMessage({id: 'toast_error'}));
            }
        } catch(error){
            toast.error(intl.formatMessage({id: 'toast_error'}))
        }
    }

    const deleteProfile = async data => {

        const deleteData = {
            password: data.password,
        }
        try{
            const response = await axios.post(
                    `${props.host}deleteprofile`, deleteData, {
                      headers: {
                        "Content-Type": "application/json",
                        xtoken: cookie.load("xtoken"),
                      },
                    })

                if(response.data.status === 'success'){
                    toast.success(intl.formatMessage({id: 'profile_toast_profile_deleted'}));
                    deleteForm.reset();
                } else if(response.data.status === 'failed'){
                    toast.warning(intl.formatMessage({id: 'profile_toast_profile_not_deleted'}));
                }

            } catch (error) {
                toast.error(intl.formatMessage({id: 'toast_error'}));
                return;
            }
    }

    const updateCompany = async data => {

        const companyData = JSON.stringify({
              password: data.password,
              newCompany: data.newCompany,
            });
            const response = await axios.post(
                `${props.host}updatecompany`,
                companyData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        xtoken: cookie.load("xtoken"),
                    }
                })

            if(response.data.status === 'success'){
                await updatePage();
                companyForm.reset();
                toast.success(intl.formatMessage({id: 'profile_toast_company_changed'}));
            } else if(response.data.status === "failed"){
                toast.warning(intl.formatMessage({id: 'toast_error'}))
            }
    }

    const sendNewClientInfo = async data => {

            const newUserData = {
                name: data.name,
                secondName: data.secondName,
                tittle: data.position,
                company: data.company,

            }
            try {
                const response = await axios.post(
            `${props.host}sendclientpersonalinfo`,
                    newUserData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            xtoken: cookie.load("xtoken"),
                        }
                    }
                );
            if(response.data.status === 'success'){
                setClientInfo(data);
                toast.success(intl.formatMessage({id: 'profile_toast_customerdata_changed'}))
                newUserForm.reset();
            } else if(response.data.status === 'failed'){
                toast.warning(intl.formatMessage({id: 'toast_error'}))
                console.log(response.data.message)
            }
            } catch (error){
                toast.error(intl.formatMessage({id: 'toast_error'}))
            }
    }


    const submitError = (errors) => {
        let displayedMessages = [];

        Object.values(errors).forEach(error => {
            if (error.message && !displayedMessages.includes(error.message)) {
                toast.warning(error.message);
                displayedMessages.push(error.message);
            }
        });
    };

      const isEmail = (value) => {
        return inputRegex.email.test(value) || "Wrong email format"
      };

      const isPassword = (value) => {
        return inputRegex.password.test(value) || "Wrong password format. Should contain one letter uppercase, one lower, one digit and be at least 8 chars long";
      };

      const isText = (value) => {
        return inputRegex.string.test(value) || "Wrong text format";
      };

    const updatePage = async () => {
        axios
          .get(`${props.host}selectsclientinfo`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                let clientInfo = response.data.result[0];
                setClientInfo(clientInfo)
              } else if (response.data.status === "failed") {
                alert(intl.formatMessage({id: 'profile_toast_session_expired'}));
                Logout();
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    const isCampaignFinishedFunc = async () => {
        axios
          .get(`${props.host}getcampaignstatus`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                if (response.data.message !== null) {
                  let campaignDateEnd = response.data.message;
                  let newCampaignDateEnd = campaignDateEnd.substr(0, 10);
                  setIsCampaignFinished(true);
                  setCampaignDateEnd(newCampaignDateEnd)
                } else {
                  console.log("[?] campaign isn't finished");
                  setIsCampaignFinished(false);
                }
              } else if (response.data.status === "failed") {
                console.log("failed");
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
      }

      const isScenarioActiveFunc = async () => {
         axios
          .get(`${props.host}isscenarioactive`, {
            headers: {
              "Content-Type": "application/json",
              xtoken: cookie.load("xtoken"),
            },
          })
          .then(
            function (response) {
                if(response.data.status === 'success'){
                    const result = response.data.result
                    let active = false;

                    result.forEach((element) => {
                        if(element.active) active = true
                    })
                    setIsScenarioActive(active);
                }
            }
          )
          .catch(function (error) {
            console.log(error);
            setIsScenarioActive(false);
          });
      }

    return (
        <>
            <Render
                clientInfo={clientInfo}
                scenariosSubjects={scenariosSubjects}
                statisticsInfo={statisticsInfo}
                statisticsPhishing={statisticsPhishing}
                isScenarioActive={isScenarioActive}

                acceptRulesCheckbox={acceptRulesCheckbox}
                rulesNotAccepted={rulesNotAccepted}
                inputRegex={inputRegex}

                isCampaignFinished={isCampaignFinished}
                campaignDateEnd={campaignDateEnd}

                isCampaignFinishedFunc={isCampaignFinishedFunc}
                isScenarioActiveFunc={isScenarioActiveFunc}

                // forms
                emailForm={emailForm}
                passwordForm={passwordForm}
                positionForm={positionForm}
                companyForm={companyForm}
                deleteForm={deleteForm}
                newUserForm={newUserForm}

                // validation patterns usage
                isEmail={isEmail}
                isPassword={isPassword}
                isText={isText}

                // successful submit
                updateEmail={updateEmail}
                updatePosition={updatePosition}
                updatePassword={updatePassword}
                updateCompany={updateCompany}
                deleteProfile={deleteProfile}
                sendNewClientInfo={sendNewClientInfo}

                // unsuccessful submit
                submitError={submitError}
            />
        </>
    )
}