import {NotificationComponent} from "../../functions/functions";
import {NavLink} from "react-router-dom";
import {Editor} from "react-draft-wysiwyg";
import Popup from "../../popup/popup";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import classNames from "classnames";

export const Render = (props) => {
    const intl = useIntl();

    return (
        <>
            <NotificationComponent position="top-right"/>
            <div className="scenary">
                <NavLink to="/cabinet/scenaries" className="btn_back-to-scenarios" onClick={props.toggleIsClicked}>
                    <i className="fas fa-long-arrow-alt-left"></i><FormattedMessage id="scenary_back_to_scripts"/>
                </NavLink>
                <div className="scenary-warning-massage">
                    {props.scenariotype === "credentials" ?
                        <div className="scenary_link-to-fake-page information-block"></div> : null}
                    <div className="information-block">
                        <div className="information-block_top">
                            <h5><FormattedMessage id="scenary_mandatory"/></h5>
                        </div>
                        <div className="information-block_bottom">
                            <p>
                               <FormattedMessage id="scenary_can_edit"/>
                                <span className="bold-font">_Firstname_ _Lastname_</span><FormattedMessage id="scenary_and"/>{" "}
                                <span className="bold-font">_linkinfodesk_</span>, <span
                                className="bold-font">_linkchangepassword_</span>, <span
                                className="bold-font">_linklogin_</span>,{" "}
                                <span className="bold-font">_datetoday__</span>, <span
                                className="bold-font">_date3months_ </span>, <span
                                className="bold-font">_attachmentdefault_</span>{" "}
                                <span className="bold-font">_htmlblockstart_ ,</span>
                                <FormattedMessage id="scenary_start_of"/>
                                <span className="bold-font">_htmlblockend_,</span>
                                <FormattedMessage id="scenary_end_of"/>
                                <span className="bold-font">_attachmentlnk_</span>
                                <FormattedMessage id="scenary_or_if"/><span
                                className="bold-font">_attachmentcustom_="name of your attache;"</span>
                                <FormattedMessage id="scenary_when_the_actions"/>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="scenary__container">
                    <div  className={classNames("scenary__container__header", {'active_frame': props.menuOpened, 'add_frame': props.focusedInput === 1 } )} >
                        <label>Subject:</label>
                        <input type="text" ref={props.scenarySubject} className="scenary__container__header__input" defaultValue={props.scenarioSubject} disabled={!props.menuOpened}
                        onFocus={()=> props.handleFocus(1)}
                        onBlur={props.handleBlur}
                        />
                    </div>
                    <div className={classNames("scenary__container__from-adress", {'active_frame': props.menuOpened, 'add_frame': props.focusedInput === 2  } )}
                         onFocus={props.toggleFrameToFromAdress}
                         onBlur={props.toggleFrameToFromAdress}>
                        <label>From:</label>
                        <input
                            type="email"
                            ref={props.scenaryFromAddressRef}
                            className="scenary__container__from-adress__input"
                            defaultValue={props.fromAddress}
                            required
                            disabled={!props.menuOpened}
                            onFocus={()=> props.handleFocus(2)}
                            onBlur={props.handleBlur}
                        />
                    </div>
                    <br/>
                    <div className={classNames('scenary__container__text', { active_frame: props.menuOpened, 'add_frame': props.focusedInput === 3 })}>
                        <div>
                            <Editor editorState={props.editorState} wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor" onEditorStateChange={props.onEditorStateChange}
                                    readOnly={props.isReadonly}
                                    onFocus={()=> props.handleFocus(3)}
                                    onBlur={props.handleBlur}
                                    />
                        </div>
                    </div>

                    <div className="scenary__container__btns layout">
                        {props.isActive ?
                            <button
                                type="submit"
                                className={classNames("submit__btn submit__btn__save btn__changeinfo", { none: !props.menuOpened })}
                                value="Submit"
                                onClick={props.showBPopup}
                            >
                                <FormattedMessage id="scenary_popup_save"/>
                            </button>
                            : (
                                <button className={classNames("submit__btn submit__btn__red btn__changeinfo", { none: props.menuOpened })}
                                        onClick={props.allowRed}>
                                    <FormattedMessage id="scenary_edit"/>
                                </button>
                            )}
                        <div>
                            {props.showPopUp ? (
                                <div className="popup-overlay">
                                    <Popup
                                        TextMessage={intl.formatMessage({id: 'scenary_popup_message'})}
                                        okFunction={props.saveHtml}
                                        okMessage={intl.formatMessage({id: 'scenary_popup_save'})}
                                        cancelMessage={intl.formatMessage({ id: 'scenary_popup_cancel' })}
                                        cancelFunction={props.showBPopup}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}