import React, {useEffect, useState} from "react";
import axios from "axios";
import "./fakepageformail.css"
import logoExample from "../../../../../assets/images/cabinet/scenary/fakepage/logo-example.png";

import {NotificationComponent} from "../../../../functions/functions";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

export const FakePageForCustomerPasswordChange = (props) => {
    const [logo, setLogo] = useState(logoExample);
    const [background, setBackground] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        let getparams = window.location.search;
        if(getparams){
            axios
          .get(`${props.host}fakepage${getparams}`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                if (response.data.logoandbg[0].logo && response.data.logoandbg[0].background) {
                    setLogo(response.data.logoandbg[0].logo);
                    setBackground(`url(${response.data.logoandbg[0].background})  `)
                } else {
                    backToDefaults();
                }
              } else if (response.data.status === "failed") {
                console.log("response failed in useEffect (FakePageForCustomerPasswordChange component)");
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
        }
    }, []);

    const backToDefaults = () => {
        setLogo(logoExample);
        setBackground(null);
    }

    const insertInfoAboutFeltInForm = () => {
        let params = new URL(document.location).searchParams;
        let keyencoded = params.get("k");
        let staffid = params.get("i");
        let scenarioid = params.get("s");

        let data = JSON.stringify({
          keyencoded: keyencoded,
          staffid: staffid,
          scenarioid: scenarioid,
        });

        axios
          .post(`${props.host}insertinfoaboutfeltinform`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(
            function (response) {
              if (response.data.status === "success") {
                  toast.success("Changes made successfully Changes made successfully")
                  navigate("/whatisphishing");
              } else if (response.data.status === "failed") {
                console.log("response failed in insertInfoAboutFeltInForm (FakePageForCustomerPasswordChange)");
              }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
    }

    return (
        <>
            <NotificationComponent position="top-right"/>
            <div className="confirm-reg" style={{backgroundImage: background}}>
                <div className="logo">
                    <img className="logo-example_img" alt="no image" src={logo}/>
                </div>

                <div className="form-contaigner">
                    <div className="form-contaigner_top">
                        <h4>Password change</h4>
                        <h5>Please confirm your details for the password change</h5>
                    </div>
                    <div className="form-contaigner_bottom">
                        <form className="form">
                            <input id="username" className="form-inputfield" placeholder="Username" type="text"
                                   required/>
                            <br/>

                            <input id="oldpassword" className="form-inputfield" placeholder="Old password"
                                   type="password"/>
                            <br/>
                            <input id="newpassword" className="form-inputfield" placeholder="New password"
                                   type="password"/>
                            <br/>
                            <input className="form-button" type="button"
                                   onClick={insertInfoAboutFeltInForm} value="Continue"/>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
